import axiosInstance from "../../helpers/api";
import * as types from "./types";

export const getCurrentUserStart = () => ({
  type: types.GET_CURRENT_USER_START,
});

export const getCurrentUserSuccess = (payload) => ({
  type: types.GET_CURRENT_USER_SUCCESS,
  payload,
});

export const addCompany = (singleCompany) => (dispatch) => {
  dispatch({
    type: types.ADD_COMPANY,
    payload: singleCompany,
  });
};

export const getCurrentUserFailed = (payload) => ({
  type: types.GET_CURRENT_USER_FAILURE,
  payload,
});

export const getCurrentUser = () => (dispatch) => {
  dispatch(getCurrentUserStart());
  return axiosInstance
    .get("/users/current")
    .then(({ data }) => {
      dispatch(getCurrentUserSuccess(data));
    })
    .catch((err) => {
      dispatch(getCurrentUserFailed({ message: err.response.data.error }));
    });
};
