import RiskLevel from "./RiskLevel";

class ReportResults {
  static present = (results) => {
    const jurisdictions = results
      .reduce((grouped, result) => {
      const jurisdiction = result.jurisdiction;
      grouped[jurisdiction] = grouped[jurisdiction] || { rules: {} };
      this.addResult(grouped, jurisdiction, result);
      return grouped;
    }, {})

    Object.entries(jurisdictions).forEach(([jurisdiction, data]) => {
      data.answer = this.calculateOverallAnswer(data.rules);
      data.risk_score = this.calculateOverallRiskScore(data.rules);
      data.risk_level = RiskLevel.calculate(data.risk_score);
      data.category = this.calculateCategory(data.answer, data.risk_level);
      data.actionItemCount = this.calculateActionItemCount(data.rules);
      data.openActionItemCount = this.calculateOpenActionItemCount(data.rules);
    });

    return {
      jurisdictions,
    };
  }

  static calculateCategory = (answer, riskLevel) => {
    if (['pending', 'permitted'].includes(answer)) {
      return answer;
    }

    return riskLevel;
  }

  static calculateOverallAnswer = (rules) => {
    const answers = this.findAllAnswers(rules);

    if (answers.some((answer) => answer === "not_permitted")) {
      return "not_permitted";
    } else if (answers.some((answer) => answer === "conditional")) {
      return "conditional";
    } else if (answers.some((answer) => answer === "pending")) {
      return "pending";
    }
    return "permitted";
  }

  static calculateOverallRiskScore = (rules) => (
    Math.max(
      ...Object
        .entries(rules)
        .map(([description, rule]) => (
          rule.results.map((result) => result.risk_score)
        ))
        .flat()
    )
  )

  static calculateActionItemCount = (rules) => (
    Object.entries(rules).reduce((sum, [description, data]) => (
      data.results.filter((result) => result.answer === 'conditional').length + sum
    ), 0)
  )

  static calculateOpenActionItemCount = (rules) => (
    Object.entries(rules).reduce((sum, [description, data]) => (
      data.results.filter((result) => result.status === 'open').length + sum
    ), 0)
  )

  static findAllAnswers = (rules) => (
    Object
      .entries(rules)
      .map(([rule, data]) => (
        data.results.map((result) => result.answer)
      ))
      .flat()
  )

  static addResult = (data, jurisdiction, result) => {
    const rules = data[jurisdiction].rules;
    const description = result.description;

    rules[description] = rules[description] || { rationale: result.rationale, results: [], };

    const existingActionItems = rules[description].results.map((result) => result.action_item?.name);

    if (!existingActionItems.includes(result.action_item?.name)) {
      rules[description].results.push({
        id: result.id,
        answer: result.answer,
        status: result.status,
        risk_score: result.risk_score,
        action_item: result.action_item,
      });
    }
  }
}

export default ReportResults;
