import React from "react";

import { Button, Icon, Dropdown } from "semantic-ui-react";

const CaseDropdown = ({defaultOption, options, disabled}) => {
  return (
    <>
      {defaultOption}
      <Dropdown
        disabled={disabled}
        item
        className="casefile-dropdown"
        direction="left"
        options={options}
        icon={null}
        trigger={
          <Button basic className="tertiary dropdown">
            <Icon name="chevron down" />
          </Button>
        }
      />
    </>
  );
};

export default CaseDropdown;
