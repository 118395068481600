import {
  DELETE_CASE_START,
  DELETE_CASE_SUCCESS,
  DELETE_CASE_FAILURE,
} from "../actions/types";

const initialState = {
  isLoading: false,
  isCaseDeleted: false,
  errors: null,
};

const deleteCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CASE_START:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCaseDeleted: true,
      };
    case DELETE_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default deleteCaseReducer;
