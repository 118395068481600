import React from "react";
import { Button, Image } from "semantic-ui-react";
import Astronaut4Image from "../assets/astronaut-4.png";
import "./create-account.css";

const GetStarted = ({ nextStep }) => {
  return (
    <>
      <div className="create-account-get-started-wrapper">
        <Image centered src={Astronaut4Image} />
        <p className="title-1 create-account-get-started-title">
          Create an Account
        </p>
        <p className="copy-2 create-account-get-started-description">
          We look forward to helping you navigate the complicated world of data
          privacy laws. Before we do, we need a few basic details from you to
          create your account.
        </p>
        <Button
          onClick={nextStep}
          primary
          content="Get Started"
        />
      </div>
    </>
  );
};

export default GetStarted;
