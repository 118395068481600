import React from "react";
import { useParams } from "react-router-dom";
import CompanyProfile from "./CompanyProfile";
import Profile from "./Profile";
// import ComingSoon from "../coming-soon/ComingSoon";
import Error from "../error/Error";
import TeamProfile from './TeamProfile'

const Account = () => {
  const { action } = useParams();
  if (action === "company") {
    return <CompanyProfile />;
  } else if (action === "profile") {
    return <Profile />;
  }
  else if (action === 'team' ) {
    return <TeamProfile />;
  }
  return <Error />;
};

export default Account;
