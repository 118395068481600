import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { addCase } from "../../redux/actions/createOrUpdateCase";
import MultiSelect from "../input/MultiSelect";
import useOptions from "../hooks/useOptions";
import useValidation from "../hooks/useValidation";

import {
  accessorsTooltip,
  usesTooltip,
  accessLocationTooltip,
} from "../constant/tooltips";

const DataPurpose = forwardRef(({ setValid }, ref) => {
  const singleCase = useSelector((state) => state.createCaseReducer.case);
  const dispatch = useDispatch();

  const fields = [
    { name: "accessors", type: "text" },
    { name: "uses", type: "text" },
    { name: "access_locations", type: "text" },
  ];

  const { error, setError } = useValidation({
    model: singleCase,
    ref,
    setValid,
    fields,
  });

  const updateCase = (field, value) => {
    const newCase = {
      ...singleCase,
      [field]: value,
    };
    dispatch(addCase(newCase));
    setError({ ...error, [field]: "" });
  };

  const options = useOptions();

  return (
    <>
      <div className="modal-heading-description">
        <p className="navig">Data Purpose</p>
        <p className="modalTitle">Who will be accessing the data and why?</p>
      </div>
      <Form size="big" className="form-background">
        <MultiSelect
          placeholder="Please select one"
          options={options.accessorsOptions}
          error={error.accessors}
          title="Who will access the data "
          tooltip={accessorsTooltip}
          value={singleCase.accessors}
          onChange={(_, { value }) => {
            updateCase("accessors", value);
          }}
        />
        <MultiSelect
          placeholder="Select as many as applicable"
          options={options.usesOptions}
          error={error.uses}
          title="What will the data be used for"
          tooltip={usesTooltip}
          value={singleCase.uses}
          onChange={(_, { value }) => {
            updateCase("uses", value);
          }}
        />
        <MultiSelect
          placeholder="Select as many as applicable"
          options={options.countriesOptions}
          error={error.access_locations}
          title="Where will the data be accessed"
          tooltip={accessLocationTooltip}
          value={singleCase.access_locations}
          onChange={(_, { value }) => {
            updateCase("access_locations", value);
          }}
        />
      </Form>
    </>
  );
});

export default DataPurpose;
