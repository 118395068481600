import React from "react";
import { Feed, Header, Segment, Image } from "semantic-ui-react";

import "./team-member-card.css";

const TeamMemberCard = ({ value, dropdown, id }) => {
  const initials = value.first_name
    ? value.first_name?.charAt(0) + value.last_name?.charAt(0)
    : "NA";
  
  const capitalizeRole = (role) => {
     return role.charAt(0).toUpperCase() + role.slice(1)
   }
  return (
    <Segment className="member-card" clearing>
      <Header className="button-and-select" as="h2" floated="right">
        {dropdown(id)}
      </Header>
      <Header as="h2" floated="left">
        <Feed>
          <Feed.Event>
            <Feed.Label>
              <div className="profile-avatar">
                {value.profile_picture !== null ? (
                  <Image src={value.profile_picture} alt="profile image" />
                ) : (
                  <p className="title-3">{initials}</p>
                )}
              </div>
            </Feed.Label>
            <Feed.Content>
              <div className="member-name">
                <p className="title-2">
                  {value.first_name} {value.last_name}{" "}
                  {value.role !== null ? <>({capitalizeRole(value.role)})</> : ""}
                </p>
              </div>
              <div className="member-role">
                <p className="copy-3">{value.job_title}</p>
              </div>
            </Feed.Content>
          </Feed.Event>
        </Feed>
      </Header>
    </Segment>
  );
};

export default TeamMemberCard;
