import React from "react";
import { Grid } from "semantic-ui-react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as IconCases } from "../assets/icon-cases.svg";
// import { ReactComponent as IconAdvisor } from "../assets/icon-advisor.svg";
// import { ReactComponent as IconDash } from "../assets/icon-dash.svg";

import "./layout.css";

import clsx from "clsx";

const Sidebar = () => {
  const path = useLocation().pathname;
  const isSecondaryRoute = useRouteMatch("/cases/:id/report/:action");
  const isErrorRoute = useRouteMatch("/error");

  const linkClasses = (root) => {
    if (root === "/cases" && isErrorRoute) {
      return "secondary";
    }
    if (path.startsWith(root) && (isSecondaryRoute || isErrorRoute)) {
      return "secondary";
    }

    if (path.startsWith(root)) {
      return "primary";
    }
  };

  const activeIconStyle = {
    height: '45px',
    width: '45px',
    backgroundColor: '#EAE9F8',
    borderRadius: "50%",
    padding: '10px 5px 5px 8px',
  }

  const nonActiveIconStyle = {
    height: '45px',
    width: '45px',
    backgroundColor: 'transparent',
    borderRadius: "50%",
    padding: '10px 5px 5px 8px',
  }
  return (
    <Grid.Column className="sidebarContainer" width={1} textAlign="center">
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="sidebar-icons">
        <div className={clsx("icon-item", linkClasses("/cases"))} style={path.includes('/cases') ? activeIconStyle : nonActiveIconStyle}>
          <Link to="/cases">
            <IconCases className="sidebar-icon" />
          </Link>
        </div>
        {/* <div className={clsx("icon-item", linkClasses("/advisor"))} style={path === '/advisor' ? activeIconStyle : nonActiveIconStyle}>
          <Link to="/advisor">
            <IconAdvisor className="sidebar-icon" />
          </Link>
        </div>
        <div className={clsx("icon-item", linkClasses("/activity"))} style={path === '/activity' ? activeIconStyle : nonActiveIconStyle}>
          <Link to="/activity">
            <IconDash className="sidebar-icon" />
          </Link>
        </div> */}
      </div>
    </Grid.Column>
  );
};

export default Sidebar;
