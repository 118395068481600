/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import useValidation from '../hooks/useValidation'
import { Form } from 'semantic-ui-react'
import Input from '../input/Input'
import InputText from '../input/InputText'
import Auth from '../../helpers/auth'
import { createTeam, clearCreateTeamErrors } from '../../redux/actions/createTeam'
import { getTeam } from '../../redux/actions/getTeam'

import './create-team.css'

const CreateTeam = (props) => {
  const ref = useRef()

  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    job_title: '',
    email: '',
    role: '',
  })
  const [valid, setValid] = useState(false)
  const [open, setOpen] = useState(props.open)
  const dispatch = useDispatch()
  const { errors, isTeamMemberCreated } = useSelector(
    (state) => state.createTeamReducer,
  )

  const options = [
    { text: 'Admin', value: 'Admin' },
    { text: 'Team', value: 'Team' }
  ]

  const fields = [
    { name: 'first_name', type: 'text' },
    { name: 'last_name', type: 'text' },
    { name: 'job_title', type: 'text' },
    { name: 'email', type: 'email' },
    { name: 'role', type: 'text' },
  ]
  const { error, setError } = useValidation({
    model: inputs,
    ref,
    setValid,
    fields,
  })

  const handleChange = (field) => (event) => {
    if (field === 'email' && !event.target.value) {
      dispatch(clearCreateTeamErrors());
    }
    setInputs({
      ...inputs,
      [field]: event.target.value,
    })
  }

  const resetFormInputs = () => {
    setInputs({
      first_name: '',
      last_name: '',
      job_title: '',
      email: '',
      role: '',
    })
  }
  const saveTeam = () => {
    const hasError = ref.current?.hasError();
    if (!hasError) {
      const newUser = inputs;
      dispatch(createTeam(newUser));
    }
    setError({ ...error });
  };

  useEffect(() => {
    if(isTeamMemberCreated){
      new Auth().passwordChangeForTeamMember(inputs.email, (error) => {
        setOpen(false);
        resetFormInputs();
        dispatch(getTeam());
      });
    }
  }, [dispatch, errors, isTeamMemberCreated]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
      size="small"
      className="modalWrapper"
    >
      <Modal.Content>
        <div className="modal-heading-description">
          <p className="navig">Add New User</p>
          <p className="modalTitle">Invite a Team Member</p>
        </div>
        <div>
          <Form size="big" className="form-background">
            <div className="split-field-wrapper">
              <div className="splited-field">
                <InputText
                  error={error.first_name}
                  title="First name"
                  value={inputs.first_name}
                  onChange={handleChange('first_name')}
                />
              </div>
              <div className="splited-field">
                <InputText
                  error={error.last_name}
                  title="Last name"
                  value={inputs.last_name}
                  onChange={handleChange('last_name')}
                />
              </div>
            </div>
            <InputText
              error={error.job_title}
              title="Job title"
              value={inputs.job_title}
              onChange={handleChange('job_title')}
            />
            <Input
              placeholder="Please select one"
              options={options}
              title="Access level of user"
              value={inputs.role}
              onChange={(_, { value }) => {
                setInputs({
                  ...inputs,
                  role: value,
                })
              }}
            />
            <InputText
              error={error.job_title}
              title="Email address"
              value={inputs.email}
              onChange={handleChange('email')}
            />
          </Form>
        </div>
      <div>
        <p className="error-message">{errors?.message}</p>
      </div>
      </Modal.Content>

      <div className="modalActions">
        <div>
          <span
            className="cancel-btn actionbtn"
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </span>
        </div>

        <Button
          primary
          className={clsx('finish-button', !valid && 'disabled-btn')}
          onClick={saveTeam}
        >
          Send Invite
        </Button>
      </div>
    </Modal>
  )
}

export default CreateTeam
