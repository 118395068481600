import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadOptions } from "../../redux/actions/loadOptions";
import { getItem } from "../../helpers/localStorage";

const useOptions = () => {
  const [subjectData, setSubjectData] = useState(
    JSON.stringify({
      subject: "Individual Customers",
      sub_subject: "Prospective",
    })
  );
  const dispatch = useDispatch();
  const localstorageCase = getItem("singleCase") || {};

  useEffect(() => {
    dispatch(loadOptions());
    if (Object.keys(localstorageCase).length !== 0) {
      setSubjectData(JSON.stringify({
        subject: localstorageCase.subject,
        sub_subject: localstorageCase.sub_subject,
      }))
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const { isLoading, options } = useSelector(
    (state) => state.loadOptionsReducer
  );

  const {
    data_subjects,
    countries,
    data_types,
    data_uses,
    accessors,
    storage_managers,
    retrieved_from,
    sectors,
  } = options;

  const populateDataSubjects = (data) => {
    if (data) {
      const dataSubjects = [];

      Object.keys(data).forEach((subject) => {
        if (data[subject]) {
          data[subject].forEach((sub_subject) => {
            dataSubjects.push({
              text: `${sub_subject} ${subject}`,
              value: JSON.stringify({ subject, sub_subject }),
            });
          });
        } else {
          dataSubjects.push({
            text: subject,
            value: JSON.stringify({ subject, sub_subject: "" }),
          });
        }
      });
      return dataSubjects;
    }
  };

  const populateSectors = (data) => {
    if (data) {
      const sectors = [];

      data.forEach((sector) => {
        sectors.push({
          text: sector,
          value: sector,
        });
      });
      return sectors;
    }
  };

  const withOnlySubject = (data, subject) => {
    return Array.isArray(data[subject]);
  };

  const populateData = (data, subjectData) => {
    if (data) {
      const { subject, sub_subject } = JSON.parse(subjectData);

      if (withOnlySubject(data, subject)) {
        return data[subject]?.map((option) => {
          return { text: option, value: option };
        });
      }

      return data[subject][sub_subject].map((option) => {
        return { text: option, value: option };
      });
    }
  };

  const populateCountries = (countries) => {
    return countries?.map((country) => {
      return { text: country.country_name, value: country.country_name };
    });
  };

  const dataSubjectOptions = populateDataSubjects(data_subjects);
  const sectorOptions = populateSectors(sectors);
  const retrievedFromOptions = populateData(retrieved_from, subjectData);
  const storageManagersOptions = populateData(storage_managers, subjectData);
  const accessorsOptions = populateData(accessors, subjectData);
  const usesOptions = populateData(data_uses, subjectData);
  const typesCollectedOptions = populateData(data_types, subjectData);
  const countriesOptions = populateCountries(countries);

  return {
    setSubjectData,
    isLoading,
    dataSubjectOptions,
    typesCollectedOptions,
    countriesOptions,
    retrievedFromOptions,
    storageManagersOptions,
    accessorsOptions,
    usesOptions,
    sectorOptions,
  };
};

export default useOptions;
