import * as types from "./types";

export const loginSuccess = () => (dispatch) => {
  dispatch({
    type: types.LOGIN_SUCCESS,
    payload: {
      authenticated: true,
    },
  });
};
