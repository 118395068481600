import React from "react";
import { Grid } from "semantic-ui-react";
import Sidebar from "./Sidebar";

import "./layout.css";

const FullPage = ({ frame }) => {
  return (
    <Grid className="dashboardContainer" stackable={true} padded>
      <Sidebar />
      <Grid.Column width={15}>
        <div className="profile">{frame}</div>
      </Grid.Column>
    </Grid>
  );
};

export default FullPage;
