import React from "react";
import "./open-case.css";
import { Button, Image } from "semantic-ui-react";
import Astronaut from "../assets/astronaut-2.png";
import CreateCase from "../create-case/CreateCase";

const OpenCase = () => {
  return (
    <div className="open-case-wrapper">
      <Image src={Astronaut} />
      <div className="case-description">
        <div className="title-1">Open a New Case</div>
        <p className="copy-2">
          Use our step-by-step guide to provide{"\n"}
          details about the data you are collecting{"\n"}
          and its purpose. Our powerful technology{"\n"}
          and legal advisors will then analyse your{"\n"}
          case and provide you with clear action{"\n"}
          steps to be legally compliant.
        </p>
      </div>
      <CreateCase trigger={<Button primary className="transparent">Open a New Case</Button>} />
    </div>
  );
};

export default OpenCase;
