import { combineReducers } from "redux";
import getCasesReducer from "./getCases";
import createCaseReducer from "./createCase";
import replicateCaseReducer from "./replicateCase";
import deleteCaseReducer from "./deleteCase";
import loadOptionsReducer from "./loadOptions";
import activateCaseReducer from "./activateCase";
import getCaseReducer from "./getCase";
import updateCaseReducer from "./updateCase";
import updateResultReducer from "./updateResult";
import getReportReducer from "./getReport";
import getUserReducer from "./getUser";
import createProfileReducer from "./createProfile";
import getCurrentUserReducer from "./getCurrentUser";
import updateCompanyProfileReducer from "./updateCompanyProfile";
import updateProfileReducer from "./updateProfile";
import displayNotificationReducer from "./displayNotification";
import getTeamReducer from './getTeam'
import createTeamReducer from './createTeam'
import deleteUserReducer from "./deleteUser";


export default combineReducers({
  getCasesReducer,
  getCaseReducer,
  updateCaseReducer,
  createCaseReducer,
  replicateCaseReducer,
  deleteCaseReducer,
  updateResultReducer,
  loadOptionsReducer,
  activateCaseReducer,
  getReportReducer,
  getUserReducer,
  createProfileReducer,
  getCurrentUserReducer,
  updateCompanyProfileReducer,
  updateProfileReducer,
  displayNotificationReducer,
  getTeamReducer,
  createTeamReducer,
  deleteUserReducer
});
