import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  currentUser: {},
  errors: null,
};

const getCurrentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_COMPANY:
      return {
        ...state,
        isLoading: false,
        currentUser: { ...state.currentUser, company: { ...action.payload } },
      };
    case types.ADD_PROFILE:
      return {
        ...state,
        isLoading: false,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case types.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload,
      };
    case types.GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default getCurrentUserReducer;
