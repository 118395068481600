import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Container } from "semantic-ui-react";
import InputText from "../input/InputText";
import Input from "../input/Input";
import MultiSelect from "../input/MultiSelect";
import useOptions from "../hooks/useOptions";
import useValidation from "../hooks/useValidation";
import { getCurrentUser, addCompany } from "../../redux/actions/getCurrentUser";
import { updateCompanyProfile } from "../../redux/actions/updateCompanyProfile";
import { useSelector, useDispatch } from "react-redux";

import "./update-account.css";

const CompanyProfile = ({
  getCurrentUser,
  addCompany,
  updateCompanyProfile,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const currentCompany = useSelector(
    (state) => state.getCurrentUserReducer.currentUser.company
  );

  const [valid, setValid] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch, getCurrentUser]);

  const fields = [
    { name: "name", type: "text" },
    { name: "website", type: "url" },
    { name: "number_of_employees", type: "text" },
    { name: "sector", type: "text" },
    { name: "locations", type: "text" },
  ];

  const { error, setError } = useValidation({
    model: currentCompany,
    ref,
    setValid,
    fields,
  });

  const updateCompany = (field, value) => {
    const newCompany = {
      ...currentCompany,
      [field]: value,
    };
    dispatch(addCompany(newCompany));
    setError({ ...error, [field]: "" });
  };

  const saveCompany = () => {
    const hasError = ref.current?.hasError();
    if (!hasError) {
      dispatch(updateCompanyProfile(currentCompany));
    }
  };

  const options = useOptions();

  return (
    <Container className="details company-wrapper">
      <p className="title-1">Company Profile</p>
      <div className="company-form-wrapper">
        <Form size="huge">
          <InputText
            placeholder="Example Solutions Inc."
            error={error.name}
            title="Company name"
            value={currentCompany?.name}
            onChange={(event) => {
              updateCompany("name", event.target.value);
            }}
          />
          <InputText
            placeholder="example.com"
            error={error.website}
            title="Company website"
            value={currentCompany?.website}
            onChange={(event) => {
              updateCompany("website", event.target.value);
            }}
          />
          <MultiSelect
            placeholder="Select as many as applicable..."
            options={options.countriesOptions}
            error={error.locations}
            title="Company location(s)"
            value={currentCompany?.locations}
            onChange={(_, { value }) => {
              updateCompany("locations", value);
            }}
          />
          <Input
            placeholder="Please select one"
            options={options.sectorOptions}
            error={error.sector}
            title="Sector"
            value={currentCompany?.sector}
            onChange={(_, { value }) => {
              updateCompany("sector", value);
            }}
          />
          <Input
            placeholder="Please select one"
            options={options.retrievedFromOptions}
            error={error.number_of_employees}
            title="Number of employees"
            value={currentCompany?.number_of_employees}
            onChange={(_, { value }) => {
              updateCompany("number_of_employees", value);
            }}
          />
          <Button
            size="big"
            primary
            disabled={!valid}
            onClick={() => {
              saveCompany();
            }}
          >
            Save Changes
          </Button>
        </Form>
      </div>
    </Container>
  );
};

CompanyProfile.defaultProps = {
  getCurrentUser,
  addCompany,
  updateCompanyProfile,
};

export default CompanyProfile;
