import axiosInstance from "../../helpers/api";
import * as types from "./types";

export const getReportStart = () => ({
  type: types.GET_REPORT_START,
});

export const getReportSuccess = (payload) => ({
  type: types.GET_REPORT_SUCCESS,
  payload,
});

export const getReportFailed = (payload) => ({
  type: types.GET_REPORT_FAILURE,
  payload,
});

export const getReport = (id) => (dispatch) => {
  dispatch(getReportStart());
  return axiosInstance
    .get(`/cases/${id}/report`)
    .then(({ data }) => {
      dispatch(getReportSuccess(data));
    })
    .catch((err) => {
      dispatch(getReportFailed({ message: err.response.data.error }));
    });
};
