import axiosInstance from "../../helpers/api";
import {
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "./types";

export const deleteUserStart = () => ({
  type: DELETE_USER_START,
});

export const deleteUserSuccess = (payload) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailed = (payload) => ({
  type: DELETE_USER_FAILURE,
  payload,
});

export const deleteUser = (id) => (dispatch) => {
  dispatch(deleteUserStart());
  return axiosInstance
    .delete(`/users/${id}`)
    .then(() => {
      dispatch(deleteUserSuccess());
    })
    .catch((error) => {
      dispatch(deleteUserFailed({ message: "not permitted, please contact support@privitar.com" }));
    });
};
