import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  case: {},
  errors: null,
};

const activateCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACTIVATE_CASE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ACTIVATE_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        case: action.payload,
      };
    case types.ACTIVATE_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default activateCaseReducer;
