import * as types from "./types";
import axiosInstance from "../../helpers/api";

export const getCasesStart = () => ({
  type: types.GET_CASE_START,
});

export const getCasesFailed = (payload) => ({
  type: types.GET_CASE_FAILURE,
  payload,
});

export const getCasesSuccess = (payload) => ({
  type: types.GET_CASE_SUCCESS,
  payload,
});

export const getCases = () => (dispatch) => {
  dispatch(getCasesStart());

  return axiosInstance
    .get("/cases")
    .then(({ data }) => {
      dispatch(getCasesSuccess(data));
    })
    .catch((err) => {
      dispatch(getCasesFailed({ message: "Something went wrong." }));
    });
};
