import React from "react";
import "./welcome.css";

const Welcome = () => {
  return (
    <div className="welcome-container">
      <div className="title-1">Welcome!</div>

      <p className="copy-2">
        You can edit your account at any time by clicking your initials above.
      </p>
    </div>
  );
};

export default Welcome;
