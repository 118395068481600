import React from "react";
import Card from "../card/Card";
import { useParams, Link } from "react-router-dom";

import "./update-account.css";

const AccountActions = () => {
  const { action } = useParams();

  return (
    <div className="account-actions-wrapper">
      <div className="account-actions">
        <span className="caption">Account</span>
        <Link to={`/account/profile`}>
          <Card
            header="Your Profile"
            description="Update your avatar, email, and password"
            active={action === "profile"}
          />
        </Link>
        <Link to={`/account/company`}>
          <Card
            className="in-active"
            header="Company Profile"
            description="Manage your DCN company account"
            active={action === "company"}
          />
        </Link>
          <Link to={`/account/team`}>
          <Card
            header="Team"
            description="Manage user accounts for your company"
              active={action === "team"
          }
          />
        </Link>
        {/* <Link to={`/account/payment`}>
          <Card
            header="Payment & Plan"
            description="Manage your plan and payment details"
            active={action === "payment"}
          />
        </Link>
        <Link to={`/account/settings`}>
          <Card
            header="Settings"
            description="Manage your account"
            active={action === "settings"}
          />
        </Link> */}
      </div>
    </div>
  );
};

export default AccountActions;
