import React from "react";
import { Dimmer } from "semantic-ui-react";
import "./loader.css";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { Loader as CircularLoader } from 'semantic-ui-react';

const Loader = ({message}) => (
  <Dimmer active inverted>
    <div className="loader-wrapper">
      <div className="loader-items">
        <Logo className="loader-logo" />
        <CircularLoader className="loader" size="massive" inline>
          <p className="loader-message">{message}</p>
        </CircularLoader>
      </div>
    </div>
  </Dimmer>
);

export default Loader;
