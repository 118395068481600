import React from "react";
import { Message } from "semantic-ui-react";
import { useSelector } from "react-redux";

const Notification = ({className}) => {
  const { message, hidden } = useSelector((state) => state.displayNotificationReducer);

  return (
    <Message
      positive
      className={className}
      content={message}
      hidden={hidden}
    />
  )
};

export default Notification;
