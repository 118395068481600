import React from "react";

export const dataSubjectTooltip =
  "This is the person or the people whose data is to be accessed.";
export const dataTypeTooltip =
  "These are the different sorts of data about the person / people.  This can range from anything from someone’s name, date of birth to their IP address. ";
export const originTooltip =
  "Where do the data subjects live or are otherwise located.  This will tell us where the data is coming from. ";
export const retrievedFromTooltip =
  "This will tell us many people (the data subjects) are you collecting or accessing data about. ";
export const storageManagedTooltip =
  "Who manages the technology to store the data?  Is this internal within the company (e.g. “on-premise” by the entity responsible for the case or perhaps another entity within the enterprise)? Maybe it is an external party (e.g. a cloud or services provider).  Or perhaps both?  You can select more than one option to explain this.";
export const storageLocationTooltip =
  "Which country (or countries) will the data be stored (either within your local entity, externally or both)?  This may be one single country or perhaps is across 2 or more.";
export const accessorsTooltip =
  "Is the data being viewed or otherwise accessed by someone within the company only?  Or is it viewed by a third party (e.g. service provider, advisor, consultant, regulatory body etc)?";
export const usesTooltip =
  "What do you intend to use the data for?  This is really important to be as specific as possible and provide all the uses of data you plan to do.";
export const accessLocationTooltip =
  "Which country (or countries) will the data be accessed from (this could be internally within your company, externally by a third party or both)?  This may be one single country or perhaps is across 2 or more.";
export const titleTooltip =
  "Remember what this case relates to by giving it a clear title so you and others will know what this relates to. ";
export const descriptionTooltip =
  "Provide more context and information.  You might want to list here any third parties who are involved; a simple description of your case or other information you think important for others to be aware of.  ";
export const passwordTooltip = (
  <div>
    <p>Password Policy</p>
    <ul>
      <li>Must have special characters</li>
      <li>Must have lower case (a-z), upper case (A-Z) and numbers (0-9)</li>
      <li>Must have 8 characters in length</li>
    </ul>
  </div>
);
