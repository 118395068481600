import React from "react";
import Case from "../case/Case";

const CaseList = ({ cases, dropdown, emptyMessage }) => {
  if (cases.length === 0) {
    return <p>{emptyMessage}</p>;
  }

  return cases.map((singleCase) => (
    <Case
      key={singleCase.id}
      data={singleCase}
      dropdown={dropdown}
      id={singleCase.id}
    />
  ));
};

export default CaseList;
