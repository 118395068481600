import axiosInstance from "../../helpers/api";
import * as types from "./types";
import store from "../store";
import Auth from "../../helpers/auth";

export const createProfileStart = () => ({
  type: types.CREATE_PROFILE_START,
});

export const createProfileSuccess = (payload) => ({
  type: types.CREATE_PROFILE_SUCCESS,
  payload,
});

export const createProfileFailed = (payload) => ({
  type: types.CREATE_PROFILE_FAILURE,
  payload,
});

export const addProfile = (profile) => (dispatch) => {
  dispatch({
    type: types.ADD_PROFILE,
    payload: profile,
  });
};

export const removeProfile = () => (dispatch) => {
  dispatch({
    type: types.REMOVE_PROFILE,
  });
};

export const createProfile = (history) => (dispatch) => {
  const {
    createProfileReducer: { profile: existingProfile },
  } = store.getState();

  const body = {
    first_name: existingProfile.first_name,
    last_name: existingProfile.last_name,
    job_title: existingProfile.job_title,
    email: existingProfile.email,
    password: existingProfile.password,
    company_attributes: {
      name: existingProfile.company_name,
      website: existingProfile.company_website,
      locations: existingProfile.company_locations,
      number_of_employees: existingProfile.number_of_employees,
    },
  };

  dispatch(createProfileStart());

  return axiosInstance
    .post("/users", body)
    .then(({ data }) => {
      dispatch(createProfileSuccess(data));
      dispatch(removeProfile());
      new Auth().signIn({
        email: existingProfile.email,
        password: existingProfile.password,
      });
    })
    .catch((err) => {
      dispatch(createProfileFailed({ message: err.response.data.error }));
    });
};
