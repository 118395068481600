import { useImperativeHandle, useState, useEffect, useCallback } from "react";
import isEmailValid from "../../validators/email";
import isURLValid from "../../validators/url";

const useValidation = ({ model, ref, setValid, fields }) => {
  const VALIDATORS = {
    email: isEmailValid,
    url: isURLValid,
    text: () => true,
  };

  const [error, setError] = useState({});

  const isEmpty = (value) => !value || value.length === 0;

  const validateField = useCallback(
    (field) => {
      const value = model?.[field.name];
      const isValid = VALIDATORS[field.type];

      if (!field.optional && isEmpty(value)) {
        return "This field must be completed";
      }

      if (isEmpty(value) || isValid(value)) {
        return "";
      }

      return "This field is invalid";
    },
    [model, VALIDATORS]
  );

  const validateAll = useCallback(
    () =>
      fields.reduce((errors, field) => {
        errors[field.name] = validateField(field);
        return errors;
      }, {}),
    [fields, validateField]
  );

  const anyFieldHasError = useCallback(
    (errors) => fields.some((field) => !isEmpty(errors[field.name])),
    [fields]
  );

  useEffect(() => {
    const errors = validateAll();
    setValid(!anyFieldHasError(errors));
  }, [model, setValid, anyFieldHasError, validateAll]);

  useImperativeHandle(ref, () => ({
    hasError() {
      const errors = validateAll();
      setError(errors);
      return anyFieldHasError(errors);
    },
  }));

  return { error, setError, isEmpty, model, setValid };
};

export default useValidation;
