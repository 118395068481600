import axiosInstance from "../../helpers/api";
import Auth from "../../helpers/auth";
import { displayNotification } from "./displayNotification";
import * as types from "./types";

export const updateProfileStart = () => ({
  type: types.UPDATE_PROFILE_START,
});

export const updateProfileSuccess = (payload) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload,
});

export const updateProfileFailed = (payload) => ({
  type: types.UPDATE_PROFILE_FAILURE,
  payload,
});

export const updateProfile = (newProfileData) => (dispatch) => {
  const emailChanged = !!newProfileData.new_email;

  if (emailChanged) {
    newProfileData.email = newProfileData.new_email;
    delete newProfileData.new_email;
  }

  dispatch(updateProfileStart());
  return axiosInstance
    .patch("/users/update_current", newProfileData)
    .then(({ data }) => {
      dispatch(updateProfileSuccess(data));
      dispatch(displayNotification("Your changes have been saved successfully"));

      if (emailChanged) {
        new Auth().logout();
      }
    })
    .catch((err) => {
      dispatch(updateProfileFailed({ message: err.response.data.message }));
    });
};
