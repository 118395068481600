import React from "react";
import clsx from "clsx";
import "./card.css";

const Card = ({ header, description, active, disabled }) => (
  <div className="card outer-box">
    <div
      className={clsx(
        "inner-box",
        active && !disabled && "active",
        disabled && "inactive"
      )}
    >
      <div className="title-2">{header}</div>
      <div className="copy-3">{description}</div>
    </div>
  </div>
);

export default Card;
