import React, { useEffect } from "react";
import ActiveCaseActions from "./ActiveCaseActions";
import DraftCaseActions from "./DraftCaseActions";

import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { activateCase } from "../../redux/actions/activateCase";
import { getCase } from "../../redux/actions/getCase";

const CaseActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getCase(id, history));
    }
  }, [dispatch, id, history]);

  const isLoading = useSelector((state) => state.activateCaseReducer.isLoading);
  const currentCase = useSelector((state) => state.getCaseReducer.case);

  const submitHandler = () => {
    dispatch(activateCase(id, history));
  };

  if (currentCase?.status === "draft") {
    return (
      <DraftCaseActions submitHandler={submitHandler} isLoading={isLoading} />
    );
  }

  return (
    <ActiveCaseActions submitHandler={submitHandler} isLoading={isLoading} />
  );
};

export default CaseActions;
