import React from "react";
import { Segment, Image } from "semantic-ui-react";
import Astronaut4Image from "../assets/astronaut-4.png";
import "./not-available.css";

const NotAvailable = ({ descriptionClass, title, description }) => {
  return (
    <Segment
      basic
      placeholder
      textAlign="center"
      className="not-available-container"
      vertical
    >
      <Image centered src={Astronaut4Image} />
      <p className="title-1">{title}</p>
      <p className={descriptionClass}>{description}</p>
    </Segment>
  );
};

export default NotAvailable;
