import {
  DISPLAY_SUCCESS_NOTIFICATION,
  DISPLAY_CLEAR_NOTIFICATION
} from "../actions/types";

const initialState = {
  message: null,
  hidden: true,
};

const displayNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_SUCCESS_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
        hidden: false,
      };
    case DISPLAY_CLEAR_NOTIFICATION:
      return {
        message: null,
        hidden: true,
      }
    default:
      return state;
  }
};

export default displayNotificationReducer;
