import React from "react";
import { Popup } from "semantic-ui-react";
import { ReactComponent as IconHelp } from "../assets/icon-help.svg";
import "./input.css";

const TextArea = (props) => {
  return (
    <div className="input-wrapper">
      <div className="label-wrapper">
        <div className="label-left">
          <span className="title-3">{props.title}</span>
          <Popup
            className="tooltip"
            trigger={<IconHelp size="large" circular="true" />}
            content={props.tooltip}
            position="top center"
          />
        </div>
        <span className="copy-4 label-right">{props.instruction}</span>
      </div>
      <textarea value={props.value} onChange={props.onChange}></textarea>
      <span className="error-message">{props.error}</span>
    </div>
  );
};

export default TextArea;
