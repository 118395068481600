import React from "react";
import { Button, Image, Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { deleteCase } from "../../redux/actions/deleteCase";
import Astronaut5 from "../assets/astronaut-5.png";

import "./case-delete-confirmation.css";

const CaseDeleteConfirmation = ({ id, open, close, deleteCase }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteCase = () => {
    dispatch(deleteCase(id, history));
  }

  return (
    <Modal
      closeIcon
      onClose={close}
      open={Boolean(id)}
      className="modalWrapper delete-confirmation-modal"
    >
      <Modal.Content>
        <div className="delete-confirmation-wrapper">
          <Image centered src={Astronaut5} />
          <p className="title-1">
            Before you delete this case...
          </p>
          <p className="copy-2">
            Deleting a case file will remove all data you have previously entered. Are you sure you want to proceed?
          </p>
          <Button
            onClick={handleDeleteCase}
            primary
            content="Yes, Delete This Case"
          />
          <Button
            onClick={close}
            primary
            content="No, Keep The Case"
            className="transparent"
          />
        </div>
      </Modal.Content>
    </Modal>
  );
}

CaseDeleteConfirmation.defaultProps = {
  deleteCase,
};

export default CaseDeleteConfirmation;
