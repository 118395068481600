import React from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import { ReactComponent as IconHelp } from "../assets/icon-help.svg";

import clsx from "clsx";

import "./input.css";

const Input = ({
  title,
  tooltip,
  instruction,
  placeholder,
  options,
  onChange,
  value,
  error,
}) => {
  return (
    <div className="input-wrapper" >
      <div className="label-wrapper">
        <div className="label-left">
          <span className="title-3">{title}</span>
          {tooltip && (
            <Popup
              className="tooltip"
              trigger={<IconHelp size="large" circular="true" />}
              content={tooltip}
              position="top center"
              
            />
          )}
        </div>
        <span className={clsx("copy-4", "label-right")}>{instruction}</span>
      </div>

      <Dropdown
        className="multi-select"
        placeholder={placeholder}
        fluid
        search
        selection
        options={options}
        onChange={onChange}
        value={value}
        style={(error !== '' && error !== undefined) ? { borderColor:"#CF2C67"}:{}}
      />

      <span className="error-message">{error}</span>
    </div>
  );
};

Input.defaultProps = {
  options: [],
  value: "",
};

export default Input;
