import React, { useEffect } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import TeamMemberCard from "../team/TeamMemberCard";
import CaseDropdown from "../case-dropdown/CaseDropdown";
import Loader from "../../components/loader/Loader";
import { getTeam } from "../../redux/actions/getTeam";
import CreateTeam from "../create-team/CreateTeam";
import DeleteTeam from "../delete-team/DeleteTeam";

import "./update-account.css";

const TeamProfile = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state) => state.getCurrentUserReducer.currentUser
  );

  const { isLoading, team, errors } = useSelector(
    (state) => state.getTeamReducer
  );

  const { isLoading: deleteLoading, errors: deleteErrors } = useSelector(
    (state) => state.deleteUserReducer
  );

  useEffect(() => {
    dispatch(getTeam());
  }, [dispatch, deleteLoading, deleteErrors]);

  const dropdownRestriction = (value) => {
    return (
      (["owner", "admin"].includes(value.role) && 
      ["team"].includes(currentUser.role)) || 
      (["owner"].includes(value.role) && 
      ["admin"].includes(currentUser.role))
    );
  };

  const optionRestriction = (value) => {
    return (
      currentUser.role === "team" ||
      currentUser.role === value.role ||
      value.role === "owner"
    );
  };

  const dropDown = (value) => {
    if (dropdownRestriction(value)) {
      return <></>;
    } else {
      return (
        <CaseDropdown
          disabled={currentUser.role === value.role}
          defaultOption={
            <>
              <Button
                basic
                as={Link}
                to={`/account/profile`}
                className="tertiary action"
                disabled={!optionRestriction(value)}
              >
                {value.active ? "Edit Profile" : "Invite sent"}
              </Button>
            </>
          }
          options={dropDownOptions(value)}
        />
      );
    }
  };

  const dropDownOptions = (value) => {
    const options = [
      // { text: "Rename", value: 1, as: Link, to: `/account/profile` },
      {
        text: <DeleteTeam userId={value.id} trigger={<div>Delete</div>} />,
        value: 2,
      },
    ];

    if (!optionRestriction(value)) {
      return options;
    }
  };
  return (
    <Container className="details">
      <p className="title-1">Team</p>
      <p className="copy-1">
        Add a team of editors, admins, and advisors at your company
      </p>
      <div className="team-members-wrapper">
        <div className="user-account">
          <p className="title-2">User Accounts</p>
          <p className="error-message">
            {errors?.message || deleteErrors?.message}
          </p>
        </div>
        <div>
          {isLoading || deleteLoading ? (
            <Loader message="One moment while we load team members" />
          ) : (
            team.map((value, index) => {
              return (
                <div className="teamMember-card-container" key={index}>
                  <TeamMemberCard
                    value={value}
                    dropdown={(id) => dropDown(value)}
                  />
                </div>
              );
            })
          )}
        </div>
        {currentUser.role !== "team" && (
          <div className="button-wrapper">
            <CreateTeam trigger={<Button secondary>Add New User</Button>} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default TeamProfile;
