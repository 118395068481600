import React from "react";
import NotAvailable from "../not-available/NotAvailable";

const PendingResult = () => {
  return (
    <NotAvailable
      title="Pending Results"
      descriptionClass="copy-2"
      description="Some aspects of your case require a little further investigation. We’ll send you an email as soon as your report is ready."
    />
  );
};

export default PendingResult;
