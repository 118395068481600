import React, { useState, useRef } from "react";
import { Button, Modal } from "semantic-ui-react";
import clsx from "clsx";
import "./create-case.css";
import useStepper from "../hooks/useStepper";
import DataRequest from "./DataRequest";
import { useDispatch } from "react-redux";
import DataLocation from "./DataLocation";
import DataPurpose from "./DataPurpose";
import Final from "./Final";
import {
  removeCase,
  createOrUpdateCase,
} from "../../redux/actions/createOrUpdateCase";
import { useHistory } from "react-router-dom";
import useOptions from "../hooks/useOptions";

const CreateCase = (props) => {
  const ref = useRef();
  const [valid, setValid] = useState(false);
  const options = useOptions();

  const createSteps = React.useCallback(() => {
    return [
      <DataRequest ref={ref} setValid={setValid} />,
      <DataLocation ref={ref} setValid={setValid} />,
      <DataPurpose ref={ref} setValid={setValid} />,
      <Final ref={ref} setValid={setValid} />,
    ];
  }, []);

  const steps = createSteps(options);

  const {
    nextStep,
    previousStep,
    hasPrevious,
    hasNext,
    finish,
    content,
  } = useStepper({ steps, initialStep: props.initialStep });
  const [open, setOpen] = React.useState(props.open);
  const dispatch = useDispatch();

  let history = useHistory();

  const saveCase = () => {
    const hasError = ref.current?.hasError();
    if (!hasError) {
      dispatch(createOrUpdateCase(history));
      setOpen(false);
      finish();
    }
  };

  const validate = () => {
    const hasError = ref.current?.hasError();
    if (!hasError) {
      nextStep();
    }
  };

  const cancelHandler = () => {
    dispatch(removeCase());
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
      size="small"
      className="modalWrapper"
    >
      <Modal.Content>{content}</Modal.Content>

      <div className="modalActions">
        <div>
          {hasPrevious() && (
            <span className="back-button actionbtn" onClick={previousStep}>
              &lt; Back
            </span>
          )}
          <span
            className="cancel-btn actionbtn"
            onClick={() => {
              cancelHandler();
              setOpen(false);
              finish();
            }}
          >
            Cancel
          </span>
        </div>

        {hasNext() ? (
          <Button
            primary
            className={clsx("next-button", !valid && "disabled-btn")}
            onClick={validate}
          >
            Next
          </Button>
        ) : (
          <Button
            primary
            className={clsx("finish-button", !valid && "disabled-btn")}
            onClick={saveCase}
          >
            Save &amp; Continue
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CreateCase;
