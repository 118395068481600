import React from "react";
import Navigation from "../navigation/Navigation";
import Notification from "../notification/Notification";
import { Grid } from "semantic-ui-react";
import Sidebar from "./Sidebar";
import "./layout.css";

const Dashboard = ({ leftFrame, rightFrame }) => {
  return (
    <Grid className="dashboardContainer" stackable={true} padded>
      <Sidebar />
      <Grid.Column width={4} className="left-frame">
        <div className="profile">
          <Navigation />
          {leftFrame}
        </div>
      </Grid.Column>
      <Grid.Column width={11} className="rightFrame">
        <Notification className='notification'/>
        {rightFrame}
      </Grid.Column>
    </Grid>
  );
};

export default Dashboard;
