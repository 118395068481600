import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import InputText from "../input/InputText";
import TextArea from "../input/TextArea";
import { addCase } from "../../redux/actions/createOrUpdateCase";
import useValidation from "../hooks/useValidation";
import { titleTooltip, descriptionTooltip } from "../constant/tooltips";
const Final = forwardRef(({ setValid }, ref) => {
  const singleCase = useSelector((state) => state.createCaseReducer.case);
  const dispatch = useDispatch();
  const fields = [{ name: "title", type: "text" }];
  const { error, setError } = useValidation({
    model: singleCase,
    ref,
    setValid,
    fields,
  });

  const updateCase = (field, value) => {
    const newCase = {
      ...singleCase,
      [field]: value,
    };
    dispatch(addCase(newCase));
    setError({ ...error, [field]: "" });
  };

  return (
    <>
      <div className="modal-heading-description">
        <p className="modalPointer">Final Step</p>
        <p className="modalTitle">Open Case File</p>
      </div>
      <Form size="big" className="form-background">
        <InputText
          placeholder="Existing individual customers"
          error={error.title}
          title="Give your case a title"
          tooltip={titleTooltip}
          value={singleCase.title}
          onChange={(event) => {
            updateCase("title", event.target.value);
          }}
        />

        <TextArea
          error=""
          title="Briefly describe the nature of this case"
          instruction="Optional"
          tooltip={descriptionTooltip}
          value={singleCase.description}
          onChange={(event) => {
            updateCase("description", event.target.value);
          }}
        />
      </Form>
    </>
  );
});

export default Final;
