import React, { forwardRef } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import InputText from "../input/InputText";
import { useDispatch, useSelector } from "react-redux";
import useValidation from "../hooks/useValidation";
import { addProfile } from "../../redux/actions/createProfile";
import { passwordTooltip } from "../constant/tooltips";

const PersonalProfile = forwardRef(({ setValid, auth0Error }, ref) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.createProfileReducer.profile);

  const fields = [
    { name: "first_name", type: "text" },
    { name: "last_name", type: "text" },
    { name: "job_title", type: "text" },
    { name: "email", type: "email" },
    { name: "password", type: "text" },
    { name: "terms_agreement", type: "text" },
  ];

  const { error, setError } = useValidation({
    model: profile,
    ref,
    setValid,
    fields,
  });

  const updateProfile = (field, value) => {
    const newProfile = {
      ...profile,
      [field]: value,
    };
    dispatch(addProfile(newProfile));
    setError({ ...error, [field]: "" });
  };

  return (
    <>
      <div className="modal-heading-description">
        <p className="navig">Create an Account</p>
        <p className="title-1">Your Personal Profile</p>
      </div>
      <Form size="big" className="form-background">
        <div className="split-field-wrapper">
          <InputText
            error={error.first_name}
            title="First name"
            value={profile.first_name}
            onChange={(event) => {
              updateProfile("first_name", event.target.value);
            }}
          />
          <InputText
            error={error.last_name}
            title="Last name"
            value={profile.last_name}
            onChange={(event) => {
              updateProfile("last_name", event.target.value);
            }}
          />
        </div>
        <InputText
          error={error.job_title}
          title="Job title"
          value={profile.job_title}
          onChange={(event) => {
            updateProfile("job_title", event.target.value);
          }}
        />
        <InputText
          error={error.email || auth0Error.email}
          title="Email address"
          value={profile.email}
          onChange={(event) => {
            updateProfile("email", event.target.value.toLowerCase());
          }}
        />
        <InputText
          type="password"
          error={error.password || auth0Error.password}
          title="Password"
          tooltip={passwordTooltip}
          value={profile.password}
          onChange={(event) => {
            updateProfile("password", event.target.value);
          }}
        />
        <div className="checkbox-wrapper">
          <Checkbox
            type="checkbox"
            id="terms_agreement"
            checked={profile.terms_agreement}
            onChange={(event, data) => {
              updateProfile("terms_agreement", data.checked);
            }}
          />
          <label className="copy-3" htmlFor="terms_agreement">
            I agree to the <a href="https://www.privitar.com/dcn-trial-product-terms/" rel="noopener noreferrer" target="_blank"><u>terms</u></a> and acknowledge the use of personal data as described in the <a href="https://www.privitar.com/privacy-policy/" rel="noopener noreferrer" target="_blank"><u>privacy notice</u></a>
          </label>
        </div>
      </Form>
    </>
  );
});

export default PersonalProfile;
