import React from "react";
import { Popup } from "semantic-ui-react";
import { ReactComponent as IconHelp } from "../assets/icon-help.svg";
import "./input.css";

const InputText = (props) => {
  return (
    <div className="input-wrapper">
      <div className="label-wrapper">
        <div className="label-left">
          <span className="title-3">{props.title}</span>
          {props.tooltip && (
            <Popup
              className="tooltip"
              hoverable={false}
              trigger={<IconHelp size="large" circular="true" />}
              content={props.tooltip}
              position="top center"
            />
          )}
        </div>
        <span className="copy-4 label-right">{props.instruction}</span>
      </div>
      <div>
        <input
          className="custom-input"
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
      <span className="error-message">{props.error}</span>
    </div>
  );
};

InputText.defaultProps = {
  type: "text",
};

export default InputText;
