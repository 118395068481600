import {
  DISPLAY_SUCCESS_NOTIFICATION,
  DISPLAY_CLEAR_NOTIFICATION
} from "./types";

export const displayNotification = (message) => (dispatch) => {
  setTimeout(() => {
    dispatch({ type: DISPLAY_CLEAR_NOTIFICATION });
  }, 5000);

  dispatch({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: { message }});
};
