import {
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from "../actions/types";

const initialState = {
  isLoading: false,
  isUserDeleted: false,
  errors: null,
};

const deleteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER_START:
      return {
        ...state,
        isLoading: true,
        errors: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUserDeleted: true,
        errors: null,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default deleteUserReducer;
