import React from "react";
import { Button } from "semantic-ui-react";
import "./view-plan.css";

const ViewPlan = () => {
  return (
    <div className="view-plan-wrapper">
      <div className="content">
        <div>
          <span className="title-2">
            Unlock Action Items to Lower Your Risk Level
          </span>
          <span className="pro-badge">PRO</span>
          <p className="copy-3">
            Upgrade to the Pro plan to unlock all action items for each country.
          </p>
        </div>
        <div>
          <Button primary content="View Plans" />
        </div>
      </div>
    </div>
  );
};

export default ViewPlan;
