import axiosInstance from "../../helpers/api";
import * as types from "./types";

export const updateResultStart = () => ({
  type: types.UPDATE_RESULT_START,
});

export const updateResultSuccess = (payload) => ({
  type: types.UPDATE_RESULT_SUCCESS,
  payload,
});

export const updateResultFailed = (payload) => ({
  type: types.UPDATE_RESULT_FAILURE,
  payload,
});

export const updateResult = (caseId, result, history) => (dispatch) => {
  dispatch(updateResultStart());

  return axiosInstance
    .patch(`/cases/${caseId}/results/${result.id}`, result)
    .then(({ data }) => {
      dispatch(updateResultSuccess(data));
      history.go(0);
    })
    .catch((err) => {
      dispatch(updateResultFailed({ message: err.response?.data?.error }));
    });
};
