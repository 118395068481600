import * as types from "../actions/types";
import { getItem } from "../../helpers/localStorage";

const initialState = {
  isLoading: false,
  user: { authenticated: getItem("jwtToken") !== null },
  errors: null,
};

const getUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default getUserReducer;
