import auth0 from "auth0-js";
import dotenv from "dotenv";
import { removeItem } from "./localStorage";

dotenv.config();

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECTURI,
      responseType: "id_token token",
    });
  }

  signIn(credentials, handleError) {
    this.auth0.login(
      {
        realm: "Username-Password-Authentication",
        username: credentials.email,
        password: credentials.password,
      },
      handleError
    );
  }

  handleAuthentication(callback) {
    this.auth0.parseHash(callback);
  }

  logout() {
    this.auth0.logout({
      returnTo: process.env.REACT_APP_AUTH0_REDIRECTURI,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
    removeItem("jwtToken");
  }

  resetPassword(email, handleError) {
    this.auth0.changePassword(
      {
        connection: "Username-Password-Authentication",
        email,
      },
      handleError
    );
  }
  register(newUser, handleError) {
    this.auth0.signup(
      {
        connection: "Username-Password-Authentication",
        email: newUser.email,
        password: newUser.password,
        username: newUser.email,
        user_metadata: {
          first_name: newUser.first_name,
          last_name: newUser.last_name,
        },
      },
      handleError
    );
  }

  passwordChangeForTeamMember(email, handleError) {
    this.auth0.changePassword(
      {
        connection: "Username-Password-Authentication",
        email:email,
        result_url: process.env.REACT_APP_RESULT_URL,
        ttl_sec: 0
      },
      handleError
    );
  }
}

export default Auth;
