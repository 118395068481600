import * as types from "./types";
import axiosInstance from "../../helpers/api";


export const loadOptionsStart = () => ({
  type: types.LOAD_OPTIONS_START,
});

export const loadOptionsFailed = (payload) => ({
  type: types.LOAD_OPTIONS_FAILURE,
  payload,
});

export const loadOptionsSuccess = (payload) => ({
  type: types.LOAD_OPTIONS_SUCCESS,
  payload,
});

export const loadOptions = () => (dispatch) => {
  dispatch(loadOptionsStart());

  return axiosInstance
    .get('/kormoon_data')
    .then(({ data }) => {
      dispatch(loadOptionsSuccess(data));
    })
    .catch((err) => {

      dispatch(loadOptionsFailed({ message: 'Something went wrong.' }));
    });
};
