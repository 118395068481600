import React, { useEffect } from "react";
import Card from "../card/Card";
import { getCase } from "../../redux/actions/getCase";
import { getReport } from "../../redux/actions/getReport";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

import clsx from 'clsx';

import "./report-actions.css";

const ReportActions = () => {
  const { action, id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCase(id));
    dispatch(getReport(id));
  }, [dispatch, id]);

  const currentCase = useSelector((state) => state.getCaseReducer.case);

  const report = useSelector((state) => state.getReportReducer?.report);
  const isDisabled = report && Object.keys(report).length === 0;

  return (
    <div className="actions-wrapper">
      <div className="case-title">
        <span className="copy-3">Case Title</span>
        <p className="title-1">{currentCase?.title}</p>
      </div>
      <div className="report-actions">
        <span className="caption">Report Actions</span>
        <Link
          to={`/cases/${id}/report/risk-level`}
          className={clsx(isDisabled && "disabled")}
        >
          <Card
            header="Risk Level"
            description="An overview of your risk assessment analysis"
            active={action === "risk-level"}
            disabled={isDisabled}
          />
        </Link>
        <Link
          to={`/cases/${id}/report/actions`}
          className={clsx(isDisabled && "disabled")}
        >
          <Card
            className="in-active"
            header="Report"
            description="The results of your risk assessment"
            active={action === "actions"}
            disabled={isDisabled}
          />
        </Link>
        {/* <Link
          to={`/cases/${id}/report/advisor-review`}
          className={clsx(isDisabled && "disabled")}
        >
          <Card
            header="Advisor Review"
            description="Submit report to your legal & compliance team"
            active={action === "advisor-review"}
            disabled={isDisabled}
          />
        </Link> */}
      </div>
      <div className="case-file">
        <span className="caption">Case File</span>
        <Link to={`/cases/${id}/edit`}>
          <Card
            header="Edit Case File"
            description="Editing the case file may impact your risk level"
          />
        </Link>
      </div>
    </div>
  );
};

export default ReportActions;
