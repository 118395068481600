import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  result: {},
  errors: null,
};

const updateResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_RESULT_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.UPDATE_RESULT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: action.payload,
      };
    case types.UPDATE_RESULT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default updateResultReducer;
