import React, { useState } from "react";
import InputText from "../input/InputText";
import { Form, Button } from "semantic-ui-react";
import Auth from "../../helpers/auth";
import { useHistory } from "react-router-dom";
import { setItem } from "../../helpers/localStorage";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/actions/login";
import { getCurrentUser } from "../../redux/actions/getCurrentUser";
import ResetPassword from "../reset-password/ResetPassword";

const LoginForm = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [error, setError] = useState();
  const errorMessage = "Please check your username or password";
  const auth0Client = new Auth();
  const history = useHistory();
  const isDisabled =
    credentials.email.length === 0 || credentials.password.length === 0;
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth0Client.handleAuthentication((_error, authResult) => {
      if (authResult && authResult.accessToken) {
        setItem("jwtToken", authResult.accessToken);
        dispatch(loginSuccess());
        dispatch(getCurrentUser());
        history.push("/");
      }
    });
  });

  const loginHandler = () => {
    auth0Client.signIn(credentials, (_error, _authResult) => {
      setError(errorMessage);
    });
  };

  const handleChange = (field) => (event) => {
    setCredentials({
      ...credentials,
      [field]: event.target.value,
    });

    setError("");
  };

  return (
    <div className="login-form-wrapper">
      <div className="title-1">Login</div>
      <Form size="big">
        <InputText
          placeholder=""
          instruction="Email address"
          title="Username"
          value={credentials.email}
          onChange={handleChange("email")}
        />
        <InputText
          placeholder=""
          type="password"
          error={error}
          title="Password"
          value={credentials.password}
          onChange={handleChange("password")}
        />
        <div className="login-links">
          <Button
            className="login-button"
            primary
            disabled={isDisabled}
            onClick={loginHandler}
          >
            Login
          </Button>
          <div className="title-4">
            <ResetPassword
              trigger={
                <span className="reset-password-button">Forgot Password</span>
              }
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
