import axios from "axios";
import dotenv from "dotenv";
import { getItem } from "./localStorage";

dotenv.config();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getItem("jwtToken");
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location = "/login";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
