import React from "react";
import { Button, Segment, Image } from "semantic-ui-react";
import Astronaut2 from "../assets/astronaut-2.png";
import "./get-started.css";
import CreateCase from "../create-case/CreateCase";

const GetStarted = () => {
  return (
    <Segment
      basic
      placeholder
      textAlign="center"
      style={{ height: "100%" }}
      vertical
    >
      <Image centered src={Astronaut2} className="GS-image" />
      <h2>Open a New Case</h2>
      <p className="get-started copy-1">
        Use our step-by-step guide to provide details about the data 
        you are collecting and its purpose. Our powerful technology and legal 
        advisors  will then analyse your case and provide you with clear
         action steps to be legally compliant.
      </p>
      <CreateCase trigger={<Button primary>Get Started</Button>}/>
    </Segment>
  );
};

export default GetStarted;
