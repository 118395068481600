import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  team: [],
  isTeamMemberCreated: false,
  errors: '',
};

const createTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_TEAM_START:
      return {
        ...state,
        isLoading: true,
      };

    case types.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        team: action.payload,
        isTeamMemberCreated: true,
        errors:''
      };
    case types.CREATE_TEAM_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    case types.CLEAR_CREATE_TEAM_ERRORS:
      return {
        ...state,
        errors: ''
      }
    default:
      return state;
  }
};

export default createTeamReducer;
