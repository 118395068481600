import React from "react";
import NotAvailable from "../not-available/NotAvailable";

const ComingSoon = () => {
  return (
    <NotAvailable
      title="Coming Soon!"
      descriptionClass="copy-1"
      description="We’re working hard on powerful new tools to help you navigate the complicated world of data privacy law. You’ll be the first to know when this feature is ready."
    />
  );
};

export default ComingSoon;
