import axiosInstance from "../../helpers/api";
import * as types from "./types";

export const activateCaseStart = () => ({
  type: types.ACTIVATE_CASE_START,
});

export const activateCaseSuccess = (payload) => ({
  type: types.ACTIVATE_CASE_SUCCESS,
  payload,
});

export const activateCaseFailed = (payload) => ({
  type: types.ACTIVATE_CASE_FAILURE,
  payload,
});

export const activateCase = (id, history) => (dispatch) => {
  dispatch(activateCaseStart());

  return axiosInstance.patch(`/cases/${id}/activate`)
    .then(({ data }) => {
      setTimeout(() => {
        dispatch(activateCaseSuccess(data));
        history.push(`/cases/${id}/report/actions`);
      }, 1500);
    })
    .catch((error) => {
      dispatch(activateCaseFailed({ message: error.response.data.error }));
    });
};
