import React from "react";
import Astronaut from "../assets/astronaut-2.png";
import Loader from "../../components/loader/Loader";
import { Button, Image, Modal } from "semantic-ui-react";

import "./case-actions.css";

const DraftCaseActions = ({ submitHandler, isLoading }) => (
  <div className="case-actions-container">
    <Image src={Astronaut} />
    <div className="case-actions-instructions">
      <div className="title-1">Case File</div>
      <p className="copy-2">
        When you’re finished adding data sets, continue on to the risk
        assessment questionnaire below.
      </p>
    </div>
    <Button className="submit-btn" onClick={submitHandler} primary>
      Submit Case for Analysis
    </Button>
    <Modal
      size="fullscreen"
      open={isLoading}
    >
      <Modal.Content>
        <Loader message="One moment while we process your case"/>
      </Modal.Content>
    </Modal>
  </div>
);

export default DraftCaseActions;
