import React, { forwardRef } from "react";
import { Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { addCase } from "../../redux/actions/createOrUpdateCase";
import MultiSelect from "../input/MultiSelect";
import useOptions from "../hooks/useOptions";

import useValidation from "../hooks/useValidation";
import {
  storageManagedTooltip,
  storageLocationTooltip,
} from "../constant/tooltips";
const DataLocation = forwardRef(({ setValid }, ref) => {
  const dispatch = useDispatch();
  const singleCase = useSelector((state) => state.createCaseReducer.case);
  const fields = [
    { name: "storage_managers", type: "text" },
    { name: "storage_locations", type: "text" },
  ];

  const { error, setError } = useValidation({
    model: singleCase,
    ref,
    setValid,
    fields,
  });

  const updateCase = (field, value) => {
    const newCase = {
      ...singleCase,
      [field]: value,
    };
    dispatch(addCase(newCase));
    setError({ ...error, [field]: "" });
  };

  const options = useOptions();

  return (
    <>
      <div className="modal-heading-description">
        <p className="navig">Data Location</p>
        <p className="modalTitle">Where will the data be stored?</p>
      </div>
      <Form size="big" className="form-background">
        <MultiSelect
          placeholder="Select as many as applicable"
          options={options.storageManagersOptions}
          error={error.storage_managers}
          title="Data storage managed by"
          tooltip={storageManagedTooltip}
          value={singleCase.storage_managers}
          onChange={(_, { value }) => {
            updateCase("storage_managers", value);
          }}
        />
        <MultiSelect
          placeholder="Select as many as applicable"
          options={options.countriesOptions}
          error={error.storage_locations}
          title="Where will the data be stored"
          tooltip={storageLocationTooltip}
          value={singleCase.storage_locations}
          onChange={(_, { value }) => {
            updateCase("storage_locations", value);
          }}
        />
      </Form>
    </>
  );
});

export default DataLocation;
