import React, { useEffect } from "react";

import Case from "../case/Case";
import CaseDropdown from "../case-dropdown/CaseDropdown"
import RiskLevelPresenter from "../../presenters/RiskLevel";

import { Button, Container } from "semantic-ui-react";
import { getCase } from "../../redux/actions/getCase";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const RiskLevel = () => {
  const RISK_DEFINITIONS = {
    low: (
      <>
        <p>This is calculated as the risk of non-compliance with law, risk of harm to individuals and/or where there are actions to complete, risk for failure to carry out and implement those actions.</p>
        <p>This case is considered <u>low risk</u>.  <i>The risk may be further managed by following any actions set out in your report.</i></p>
      </>
    ),
    medium: (
      <>
        <p>This is calculated as the risk of non-compliance with law, risk of harm to individuals and/or where there are actions to complete, risk for failure to carry out and implement those actions.</p>
        <p>This case is considered <u>medium risk</u> and therefore should be considered carefully as it could expose the organisation and ultimately individuals to risk of harm.  <i>The risk may be further managed by following any actions set out in your report.</i></p>
      </>
    ),
    high: (
      <>
        <p>This is calculated as the risk of non-compliance with law, risk of harm to individuals and/or where there are actions to complete, risk for failure to carry out and implement those actions.</p>
        <p>This case is considered <u>high risk</u> and therefore should be considered very carefully as it could expose the organisation and ultimately individuals to risk of harm.  <i>The risk may be further managed by following any actions set out in your report.</i></p>
      </>
    )
  };

  const dispatch = useDispatch();
  const history = useHistory();

  let { id } = useParams();

  useEffect(() => {
    dispatch(getCase(id, history));
  }, [dispatch, id, history]);

  const currentCase = useSelector((state) => state.getCaseReducer.case);
  const riskLabel = RiskLevelPresenter.calculate(currentCase?.risk_score);

  return (
    <Container className="details">
      <p className="title-1">Risk Level</p>
      <Case
        data={currentCase}
        dropdown={(id) => (
          <CaseDropdown
            defaultOption={
              <Button
                basic
                as={Link}
                to={`/cases/${id}/edit`}
                className="tertiary action"
              >
                View Report
              </Button>
            }
            options={[
              { text: "Edit", value: 1, as: Link, to: `/cases/${id}/edit` },
              // { text: "Replicate", value: 2, onClick: handleReplicateCase(id) },
              // { text: "Delete", value: 3, onClick: handleDeleteCase(id), },
            ]}
          />
        )}
        id={currentCase.id}
      />
      <Container className="section">
        <p className="title-2">What does {riskLabel} risk mean?</p>
        <div className="copy-2">
          {RISK_DEFINITIONS[riskLabel]}
        </div>
      </Container>
      <Container className="section">
        <p className="title-2">What can you do to lower your risk?</p>
        <Button
          as={Link}
          to={`/cases/${id}/report/actions`}
          primary
        >
          View Your Report
        </Button>
      </Container>
      <Container className="section">
      <p className="title-2">What if you change the way you handle data?</p>
      <p className="copy-2">
        Any change(s) to the way you handle data may affect your risk profile. If you do make any changes, you should edit your case file or open a new case.
      </p>
      </Container>
    </Container>
  );
};

export default RiskLevel;
