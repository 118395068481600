class RiskLevel {
  static calculate(score) {
    if ([null, 0, 1, 2, 3].includes(score)) {
      return "low";
    }
    if ([4, 5, 6, 7].includes(score)) {
      return "medium";
    }
    return "high";
  }
}

export default RiskLevel;
