import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Checkbox } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { updateResult } from "../../redux/actions/updateResult";

const Action = ({resultId, name, description, actionable, status}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentCase = useSelector((state) => state.getCaseReducer.case);

  const fakeDescription = `
    Lorem ipsum dolor sit amet, in deseruisse theophrastus per.
    Sit exerci graecis no, ne erat efficiantur per.
    No sed dicam bonorum abhorreant, at reque patrioque sea.
    Eius postea ornatus ne his, ut qui summo labores. Mei at nostro eripuit.
`;

  return (
    <div className="action-item">
      <p className="title-3">
        {name}
        {status === 'resolved' && <span className="status">Resolved</span>}
      </p>
      <p className="copy-3">{description || fakeDescription}</p>
      <div className="actions">
        <Checkbox
          type="checkbox"
          checked={status === 'resolved'}
          className="copy-3"
          label="Mark this action item as resolved"
          onChange={(event, data) => {
            const result = { id: resultId, status: data.checked ? "resolved" : "open" };
            dispatch(updateResult(currentCase.id, result, history));
          }}
        />
        {
          actionable && status !== "resolved" && (
            <Button
              primary
              as={Link}
              to="/coming-soon"
              content="Get Started"
            />
          )
        }
      </div>
    </div>
  );
}

export default Action;
