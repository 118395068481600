import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  profile: {},
  isProfileAdded: false,
  isProfileCreated: false,
  errors: null,
};

const createProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_PROFILE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_PROFILE:
      return {
        ...state,
        isLoading: false,
        profile: { ...state.case, ...action.payload },
        isProfileAdded: true,
      };
    case types.REMOVE_PROFILE:
      return {
        ...state,
        profile: {},
      };
    case types.CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: action.payload,
        isProfileCreated: true,
      };
    case types.CREATE_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default createProfileReducer;
