import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  cases: [],
  errors: null,
};

const getCasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CASE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.CREATE_CASE_SUCCESS:
      return {
        ...state,
        cases: [action.payload,...state.cases],
      };
    case types.GET_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cases: action.payload.cases,
      };
    case types.GET_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default getCasesReducer;
