import React, { useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import { ReactComponent as IconClose } from "../assets/icon-close.svg";
import { deleteUser } from "../../redux/actions/deleteUser";
import { useDispatch } from "react-redux";

import "./delete-team.css";

const DeleteTeam = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id));
    setOpen(false);
  };

  useEffect(() => {}, [dispatch])
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
      size="small"
      className="modalWrapper-delete"
    >
      <Modal.Content>
        <div className="delete-modal-container">
          <IconClose
            className="icon-close"
            onClick={() => {
              setOpen(false);
            }}
          />
          <div className="delete-modal-content">
            <p className="title-1">Delete User</p>
            <div className="delete-warning-text-container">
              <p className="delete-warning-text copy-2">
                You’re about to remove a user from your team. Just double
              </p>
              <p className="delete-warning-text copy-2">checking you want to do this?</p>
            </div>
            <Button
              className="delete-modal-content"
              onClick={() => handleDeleteUser(props.userId)}
            >
              Yes, delete user
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteTeam;
