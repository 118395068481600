import React from "react";
import { Header, Segment, Feed } from "semantic-ui-react";
import RiskLevel from "../../presenters/RiskLevel";

import dateFormat from "dateformat";
import clsx from "clsx";

import "./case.css";

const Case = ({ data, dropdown, id }) => {
  const level = RiskLevel.calculate(data.risk_score);

  const label = (data) => {
    if (data.status === "draft") {
      return "D";
    }

    return level.charAt(0).toUpperCase();
  }

  return (
    <Segment className='case-card' clearing>
      <Header className="button-and-select" as="h2" floated="right">
        {dropdown(id)}
      </Header>
      <Header as="h2" floated="left">
        <Feed>
          <Feed.Event>
            <Feed.Label>
              <div className={clsx("badge", data.status, data.status === "active" && level)}>
                <p className="title-1">{label(data)}</p>
              </div>
            </Feed.Label>
            <Feed.Content>
              {data.title}
              <Feed.Date>Last updated {dateFormat(data.updated_at, "longDate")}</Feed.Date>
            </Feed.Content>
          </Feed.Event>
        </Feed>
      </Header>
    </Segment>
  );
};

export default Case;
