import React, { useEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Auth from "../../helpers/auth";
import { getCurrentUser } from "../../redux/actions/getCurrentUser";

import "./navigation.css";

const Navigation = () => {
  const auth0Client = new Auth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const currentUser = useSelector(
    (state) => state.getCurrentUserReducer.currentUser
  );
  const initial = currentUser.first_name ? (currentUser.first_name?.charAt(0) + currentUser.last_name?.charAt(0)) : 'NA';

  const logoutHandler = () => {
    auth0Client.logout();
  };

  const userOptions = [
    { value: 0, text: "Your Profile", as: Link, to: "/account/profile" },
    { value: 1, text: "Company Profile", as: Link, to: "/account/company" },
    {value: 2, text: "Team", as: Link, to: "/account/team" },
    // { value: 3, text: "Payment & Plan", as: Link, to: "/account/payment"  },
    // { value: 4, text: "Settings", as: Link, to: "/account/settings" },
    { value: 5, text: "Log Out", onClick: logoutHandler }
  ];
  
  return (
    <div className="navigation-wrapper">
      <Breadcrumbs />
      <Dropdown
        item
        simple
        className="dashboard-dropdown"
        direction="right"
        icon={null}
        trigger={
          <div className="profile-avatar">
            <p className="title-3">{initial}</p>
          </div>
        }
        options={userOptions}
      ></Dropdown>
    </div>
  );
};

export default Navigation;
