import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import LoginForm from "./LoginForm";
import LoginRight from "./LoginRight";
import { useDispatch } from 'react-redux'
import { ReactComponent as Logo } from "../assets/logo.svg";
import { displayNotification } from '../../redux/actions/displayNotification';
import { useLocation } from 'react-router-dom';
import Notification from "../notification/Notification";
import queryString from "query-string";

import "./login.css";

const Login = () => {
  const [ expired, setExpired ] = useState();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const linkExpired = (query) => {
    return (
      query.message !== undefined &&
      query.message.includes("expired") &&
      !query.loginsCount
    );
  };

  const emailVerified = (query) => {
    return (
      query.message !== undefined &&
      query.message.includes("verified") &&
      !query.loginsCount
    );
  }

  const hasQueryParams = (query) => {
    return Object.keys(query).length > 0
  }
  
  React.useEffect(() => {
    const query = queryString.parse(search);
    if(hasQueryParams(query)){
      if (linkExpired(query)){
        setExpired(true);
        dispatch(displayNotification('Link expired, please try again or contact your organisation admin'))
      } else if(emailVerified(query)){
        setExpired(false);
        dispatch(displayNotification('Your account has been successfully verified'))
      } else {
        setExpired(false);
        dispatch(displayNotification('Your password has been successfully changed'))
      }
    }
  },[search, dispatch])

  return (
    <Grid className="login-dashboard-container" stackable={true} padded>
      <Grid.Column width={4}>
        <div className="profile">
          <div className="kormoon-logo">
            <Logo />
          </div>
          <LoginForm />
        </div>
      </Grid.Column>

      <Grid.Column width={12} className="rightFrame">
        <Notification className={expired ? 'notification-error' : 'notification'} />
        <LoginRight />
      </Grid.Column>
    </Grid>
  );
};

export default Login;
