import React, { useEffect } from "react";

import ReportCard from "../report-card/ReportCard";
import ViewPlan from "../view-plan/ViewPlan";
import ReportResults from "../../presenters/ReportResults";

import { Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../redux/actions/getCurrentUser";

import "./report-details.css";

const ReportDetails = () => {
  const HUMAN_READABLE_ANSWERS = {
    conditional: "Permissable, subject to certain conditions",
    pending: "Pending",
    permitted: "Permissable",
    not_permitted: "Not Permissable",
    no_opinion: "No Opinion",
  };

  const REPORT_NOTES = {
    conditional: "This case is permitted provided you carry out all of the required actions.  If you fail to carry out these actions, you put the company at risk of violating applicable laws, regulations and policy.  Once you’ve completed all of the required actions, the commencement of your case (as you’ve outlined) will be permitted.  We monitor changes in laws, regulation and policy.  We will notify you should there be changes in the further which requires action.  In the meantime, please carry out all of the actions.  Once completed, you’re good to go.",
    permitted: "This case is permitted.  You’ve helpfully explained the proposed scope of your case and it can proceed in a compliant manner without the need for any further action at this time.  We monitor changes in laws, regulation and policy.  We will notify you should there be changes in the further which requires action.  In the meantime, you’re good to go.",
    not_permitted: "This case is not permitted.  The way you’ve described your case highlights activities (in part or full) which do not comply with applicable laws, regulations and policy.  We’ve described this in more detail below.  If you would like to revise the scope of your case, you could resubmit with those changes to ensure you’re managing personal data in a compliant way.  If you continue on, you put the company at risk of breaching law, regulation and applicable policy.",
    no_opinion: "No Opinion",
  };

  const dispatch = useDispatch();

  const allState = useSelector((state) => state);
  const report = allState.getReportReducer.report;
  const currentUser = allState.getCurrentUserReducer.currentUser;

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const isBasicUser = currentUser?.plan === "basic";
  const jurisdictions = ReportResults.present(report.results).jurisdictions;

  const groups = {
    low: {
      label: "Low Risk",
      jurisdictions: {},
    },
    medium: {
      label: "Medium Risk",
      jurisdictions: {},
    },
    high: {
      label: "High Risk",
      jurisdictions: {},
    },
    permitted: {
      label: "Permitted",
      jurisdictions: {},
    },
    pending: {
      label: "Pending",
      jurisdictions: {},
    },
  };

  Object.entries(jurisdictions).forEach(([jurisdiction, data]) => {
    groups[data.category].jurisdictions[jurisdiction] = data;
  });

  return (
    <Container className="details report-details-wrapper">
      <p className="title-1">Report</p>
      <p className="copy-1">
        Based on the information in your case file, below is a breakdown of
        action items we’ve identified as needing to be addressed in order to be
        compliant in the countries you operate in — ordered by their level of risk.
      </p>
      {
        [groups.low, groups.medium, groups.high, groups.permitted, groups.pending]
          .filter((group) => Object.keys(group.jurisdictions).length > 0)
          .map((group) => {
            return (
              <div className="report-jurisdictions" key={group.label}>
                <p className="title-2">{group.label}</p>
                {
                  Object.entries(group.jurisdictions).map(([jurisdiction, data]) => (
                    <ReportCard
                      key={jurisdiction}
                      jurisdiction={jurisdiction}
                      answer={HUMAN_READABLE_ANSWERS[data.answer]}
                      notes={REPORT_NOTES[data.answer]}
                      rules={data.rules}
                      category={data.category}
                      isBasicUser={isBasicUser}
                      actionItemCount={data.actionItemCount}
                      openActionItemCount={data.openActionItemCount}
                    />
                  ))
                }
              </div>
            )
          })
      }
      {isBasicUser && <ViewPlan />}
    </Container>
  );
};

export default ReportDetails;
