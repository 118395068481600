import React from "react";
import { Header, Icon, Segment, Button, Feed } from "semantic-ui-react";
import clsx from "clsx";
import ListActions from "../list-actions/ListActions";

import "./report-card.css";

const ReportCard = ({
  jurisdiction,
  rules,
  category,
  answer,
  notes,
  isBasicUser,
  actionItemCount,
  openActionItemCount,
}) => {
  let buttonText = 'View Actions';

  if (openActionItemCount === 0) {
    buttonText = 'View Rationale';
  }

  if (category === 'pending') {
    buttonText = 'Pending results';
  }

  return (
    <Segment className="report-card-wrapper" clearing>
      <Header as="h2">
        <Feed>
          <Feed.Event>
            <Feed.Label>
              <div className={
                clsx("badge", openActionItemCount === 0 ? "resolved" : "active", isBasicUser && "basic-plan", category)
              }>
                <p className="title-1">{openActionItemCount === 0 ? <Icon name="checkmark" /> : openActionItemCount}</p>
              </div>
            </Feed.Label>
            <Feed.Content>
              {jurisdiction}
              <Feed.Date>{answer}</Feed.Date>
            </Feed.Content>
          </Feed.Event>
        </Feed>
      </Header>
      <Header className="view-actions" as="h5">
        {isBasicUser ? (
          <div className="editText" >
            Upgrade to View
          </div>
        ) : (
            actionItemCount === 0 ? (
              <ListActions
                jurisdiction={jurisdiction}
                answer={answer}
                notes={notes}
                rules={actionItemCount === 0 ? [] : rules}
                trigger={
                  <Button
                    basic
                    disabled={actionItemCount === 0}
                    className="tertiary"
                  >
                    {buttonText}
                  </Button>
                }
              />
              
            ) : (
                <ListActions
                  jurisdiction={jurisdiction}
                  answer={answer}
                  notes={notes}
                  rules={actionItemCount === 0 ? [] : rules}
                  trigger={
                    <div
                      className="editText"
                    >
                      {buttonText}
                    </div>
                  }
                />  
            )
        )} 
      </Header>
    </Segment>
  );
};

ReportCard.defaultProps = {
  rules: {},
};

export default ReportCard;
