import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.getUserReducer.user.authenticated
  );

  if (isAuthenticated) {
    return <Route {...rest}>{children}</Route>;
  }

  return <Redirect to="/login" />;
};

export default PrivateRoute;
