import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  case: {},
  isCaseAdded: false,
  isCaseCreated: false,
  errors: null,
};

const createCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_OR_UPDATE_CASE_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_CASE:
      return {
        ...state,
        isLoading: false,
        case: { ...state.case, ...action.payload },
        isCaseAdded: true,
      };
    case types.REMOVE_CASE:
      return {
        ...state,
        case: {},
      };
    case types.CREATE_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        case: action.payload,
        isCaseCreated: true,
      };
    case types.CREATE_OR_UPDATE_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default createCaseReducer;
