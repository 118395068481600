import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  options: {},
  errors: null,
};

const loadOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_OPTIONS_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LOAD_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        options: action.payload.kormoon_data,
      };

    case types.LOAD_OPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default loadOptionsReducer;
