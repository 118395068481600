import axiosInstance from "../../helpers/api";
import {
  DELETE_CASE_START,
  DELETE_CASE_SUCCESS,
  DELETE_CASE_FAILURE
} from "./types";

export const deleteCaseStart = () => ({
  type: DELETE_CASE_START,
});

export const deleteCaseSuccess = (payload) => ({
  type: DELETE_CASE_SUCCESS,
  payload,
});

export const deleteCaseFailed = (payload) => ({
  type: DELETE_CASE_FAILURE,
  payload,
});

export const deleteCase = (id, history) => (dispatch) => {
  dispatch(deleteCaseStart());

  return axiosInstance
    .delete(`/cases/${id}`)
    .then(({ data }) => {
      dispatch(deleteCaseSuccess());
      history.go(0);
    })
    .catch((error) => {
      dispatch(deleteCaseFailed({ message: "Something went wrong." }));
      history.push("/error");
    });
};
