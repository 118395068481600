import axiosInstance from "../../helpers/api";
import {
  REPLICATE_CASE_START,
  REPLICATE_CASE_SUCCESS,
  REPLICATE_CASE_FAILURE
} from "./types";

export const replicateCaseStart = () => ({
  type: REPLICATE_CASE_START,
});

export const replicateCaseSuccess = (payload) => ({
  type: REPLICATE_CASE_SUCCESS,
  payload,
});

export const replicateCaseFailed = (payload) => ({
  type: REPLICATE_CASE_FAILURE,
  payload,
});

export const replicateCase = (id, history) => (dispatch) => {
  dispatch(replicateCaseStart());

  return axiosInstance
    .post(`/cases/${id}/replicate`)
    .then(({ data }) => {
      dispatch(replicateCaseSuccess());
      history.go(0);
    })
    .catch((error) => {
      dispatch(replicateCaseFailed({ message: "Something went wrong." }));
      history.push("/error");
    });
};
