import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./components/layout/Dashboard";
import FullPage from "./components/layout/FullPage";
import GetStarted from "./components/get-started/GetStarted";
import Welcome from "./components/welcome/Welcome";
import OpenCase from "./components/open-case/OpenCase";
import Cases from "./components/cases/Cases";
import CaseActions from "./components/case-actions/CaseActions";
import CaseDetails from "./components/case-details/CaseDetails";
import ReportActions from "./components/report-actions/ReportActions";
import Report from "./components/report/Report";
import ComingSoon from "./components/coming-soon/ComingSoon";
import Error from "./components/error/Error";
import Login from "./components/login/Login";
import AccountActions from "./components/update-account/AccountActions";
import Account from "./components/update-account/Account";
import PrivateRoute from "./components/private-route/PrivateRoute";

const App = () => (
  <Router>
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      {/* <PrivateRoute path="/advisor">
        <FullPage frame={<ComingSoon />} />
      </PrivateRoute>
      <PrivateRoute path="/activity">
        <FullPage frame={<ComingSoon />} />
      </PrivateRoute> */}
      <PrivateRoute path="/coming-soon">
        <FullPage frame={<ComingSoon />} />
      </PrivateRoute>
      <PrivateRoute path="/cases/:id/report/:action">
        <Dashboard leftFrame={<ReportActions />} rightFrame={<Report />} />
      </PrivateRoute>
      <PrivateRoute path="/cases/:id/:action">
        <Dashboard leftFrame={<CaseActions />} rightFrame={<CaseDetails />} />
      </PrivateRoute>
      <PrivateRoute path="/cases">
        <Dashboard leftFrame={<OpenCase />} rightFrame={<Cases />} />
      </PrivateRoute>
      <PrivateRoute exact path="/account/:action">
        <Dashboard leftFrame={<AccountActions />} rightFrame={<Account />} />
      </PrivateRoute>
      <PrivateRoute exact path="/">
        <Dashboard leftFrame={<Welcome />} rightFrame={<GetStarted />} />
      </PrivateRoute>
      <Route>
        <FullPage frame={<Error />} />
      </Route>
    </Switch>
  </Router>
);

export default App;
