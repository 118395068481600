import React from "react";

const useStepper = ({steps, initialStep=0}) => {
  const initialContent = steps[initialStep]
  const [currentStep, setCurrentStep] = React.useState(initialStep);
  const [content, setContent] = React.useState(initialContent);
  
  const nextStep = () => {
    setContent(steps[currentStep + 1])
    setCurrentStep(currentStep + 1)
  }

  const previousStep = () => {
    setContent(steps[currentStep - 1])
    setCurrentStep(currentStep - 1)
  }

  const hasPrevious = () => {
    return currentStep > 0
  }
  
  const hasNext = () => {
    return steps.length - 1 > currentStep
  }

  const finish = () => {
    setCurrentStep(0)
    setContent(steps[0])
  }

  return { nextStep, previousStep, hasPrevious, hasNext, finish, content, currentStep }
};

export default useStepper;
