import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import InputText from "../input/InputText";
import Auth from "../../helpers/auth";

const ResetPasswordForm = ({ closeModal }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [confirm, setConfirm] = useState(false);

  const errorMessage =
    "Something went wrong. Please check your internet connection.";
  const isDisabled = email.length === 0;

  const resetPasswordHandler = () => {
    new Auth().resetPassword(email, (error) => {
      if (error) {
        return setError(errorMessage);
      }

      setConfirm(true);
    });
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  if (confirm) {
    return (
      <div className="reset-password-description">
        <div className="title-1">Reset Password</div>
        <p className="copy-2">
          Thanks! A link to reset your password has been sent.
        </p>
        <Form size="big">
          <div className="input-wrapper">
            <Button
              primary
              className="form-item reset-password-btn"
              onClick={closeModal}
            >
              Finished
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  return (
    <div className="reset-password-description">
      <div className="title-1">Reset Password</div>
      <p className="copy-2">
        Enter your email and we’ll send you a link to reset your password.
      </p>
      <Form size="big">
        <InputText
          className="form-item"
          placeholder="Enter your email address"
          error={error}
          value={email}
          onChange={handleChange}
        />

        <div className="input-wrapper">
          <Button
            primary
            className="form-item reset-password-btn"
            disabled={isDisabled}
            onClick={resetPasswordHandler}
          >
            Reset Password
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
