import React, { useEffect } from "react";
import CaseList from "../case-list/CaseList";
import CaseDropdown from '../case-dropdown/CaseDropdown';
import CaseDeleteConfirmation from '../case-delete-confirmation/CaseDeleteConfirmation';

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Header, Container } from "semantic-ui-react";
import { getCases } from "../../redux/actions/getCases";
import { replicateCase } from "../../redux/actions/replicateCase";
import { useHistory } from "react-router-dom";

import "./cases.css";

const Cases = ({ replicateCase }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cases = useSelector((state) => state.getCasesReducer.cases);
  const draftCases = cases.filter(
    (singleCase) => singleCase.status === "draft"
  );
  const activeCases = cases.filter(
    (singleCase) => singleCase.status === "active"
  );
  const [deleteCaseId, setDeleteCaseId] = React.useState(null);

  useEffect(() => {
    dispatch(getCases());
  }, [dispatch]);

  const handleReplicateCase = (id) => () => {
    dispatch(replicateCase(id, history));
  }

  const handleDeleteCase = (id) => () => {
    setDeleteCaseId(id);
  }

  const handleCloseConfirmation = () => {
    setDeleteCaseId(null);
  }

  return (
    <Container className="details">
      <CaseDeleteConfirmation
        id={deleteCaseId}
        open={Boolean(deleteCaseId)}
        close={handleCloseConfirmation}
      />
      <Header as="h2">Case Files</Header>
      <p className="case-file-p">
        View and edit your case files and reports below.
      </p>
      <Header className="case-file-header" as="h3">
        Draft Cases
      </Header>
      <CaseList
        cases={draftCases}
        dropdown={(id) => (
          <CaseDropdown
            defaultOption={
              <Button
                basic
                as={Link}
                to={`/cases/${id}/edit`}
                className="tertiary action"
              >
                Edit Draft
              </Button>
            }
            options={[
              { text: "Rename", value: 1, as: Link, to: `/cases/${id}/rename` },
              { text: "Replicate", value: 2, onClick: handleReplicateCase(id) },
              { text: "Delete", value: 3, onClick: handleDeleteCase(id), },
            ]}
          />
        )}
        emptyMessage="You have no draft case files."
      />
      <Header className="case-file-header" as="h3">
        Active Cases
      </Header>
      <CaseList
        cases={activeCases}
        dropdown={(id) => (
          <CaseDropdown
            defaultOption={
              <Button
                basic
                as={Link}
                to={`/cases/${id}/report/actions`}
                className="tertiary action"
              >
                View Report
              </Button>
            }
            options={[
              { text: "Edit", value: 0, as: Link, to: `/cases/${id}/edit` },
              { text: "Rename", value: 1, as: Link, to: `/cases/${id}/rename` },
              { text: "Replicate", value: 2, onClick: handleReplicateCase(id) },
              { text: "Delete", value: 3, onClick: handleDeleteCase(id), },
            ]}
          />
        )}
        emptyMessage="You have no active case files."
      />
    </Container>
  );
};

Cases.defaultProps = {
  replicateCase,
};

export default Cases;
