import axiosInstance from "../../helpers/api";
import * as types from "./types";

export const getCaseStart = () => ({
  type: types.GET_SINGLE_CASE_START,
});

export const getCaseSuccess = (payload) => ({
  type: types.GET_SINGLE_CASE_SUCCESS,
  payload,
});

export const getCaseFailed = (payload) => ({
  type: types.GET_SINGLE_CASE_FAILURE,
  payload,
});

export const getCase = (id, history) => (dispatch) => {
  dispatch(getCaseStart());
  return axiosInstance
    .get(`/cases/${id}`)
    .then(({ data }) => {
      dispatch(getCaseSuccess(data));
    })
    .catch((err) => {
      dispatch(getCaseFailed({ message: err.response.data.error }));
      history.push("/error");
    });
};
