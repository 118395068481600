import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Container } from "semantic-ui-react";
import InputText from "../input/InputText";
import useValidation from "../hooks/useValidation";
import { getCurrentUser } from "../../redux/actions/getCurrentUser";
import { addProfile } from "../../redux/actions/createProfile";
import { updateProfile } from "../../redux/actions/updateProfile";
import { useSelector, useDispatch } from "react-redux";
import { passwordTooltip } from "../constant/tooltips";

import "./update-account.css";

const Profile = ({ getCurrentUser, addProfile, updateProfile }) => {
  const ref = useRef();

  const currentUser = useSelector(
    (state) => state.getCurrentUserReducer.currentUser
  );

  const { errors } = useSelector((state) => state.updateProfileReducer);

  const [valid, setValid] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch, getCurrentUser]);

  const fields = [
    { name: "first_name", type: "text", },
    { name: "last_name", type: "text", },
    { name: "job_title", type: "text", },
    { name: "new_email", type: "email", optional: true, },
  ];

  const { error, setError } = useValidation({
    model: currentUser,
    ref,
    setValid,
    fields,
  });

  const updateUser = (field, value) => {
    const newProfile = {
      ...currentUser,
      [field]: value,
    };

    dispatch(addProfile(newProfile));
    setError({ ...error, [field]: "" });
  };

  const saveProfile = () => {
    const hasError = ref.current?.hasError();
    if (!hasError) {
      dispatch(updateProfile(currentUser));
    }
  };

  return (
    <Container className="details company-wrapper">
      <p className="title-1">Personal Profile</p>
      <div className="company-form-wrapper">
        <Form size="huge">
          <div className="first-last-name">
            <div className="first-last-name-input">
              <InputText
                error={error.first_name}
                title="First name"
                value={currentUser?.first_name}
                onChange={(event) => {
                  updateUser("first_name", event.target.value);
                }}
              />
            </div>
            <div className="first-last-name-input">
              <InputText
                error={error.last_name}
                title="Last name"
                value={currentUser?.last_name}
                onChange={(event) => {
                  updateUser("last_name", event.target.value);
                }}
              />
            </div>
          </div>
          <InputText
            error={error.job_title}
            title="Job title"
            value={currentUser?.job_title}
            onChange={(event) => {
              updateUser("job_title", event.target.value);
            }}
          />
          <InputText
            error={error.email}
            title="Email address"
            value={currentUser?.new_email || currentUser?.email}
            onChange={(event) => {
              updateUser("new_email", event.target.value.toLowerCase());
            }}
          />
          <InputText
            title="Update Password"
            error={errors?.message}
            tooltip={passwordTooltip}
            type="password"
            value={currentUser?.password}
            onChange={(event) => {
              updateUser("password", event.target.value);
            }}
          />
          <Button
            size="big"
            primary
            disabled={!valid}
            onClick={() => {
              saveProfile();
            }}
          >
            Save Changes
          </Button>
        </Form>
      </div>
    </Container>
  );
};

Profile.defaultProps = {
  getCurrentUser,
  addProfile,
  updateProfile,
};

export default Profile;
