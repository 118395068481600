import axiosInstance from "../../helpers/api";
import { displayNotification } from "./displayNotification";
import * as types from "./types";

export const updateCompanyProfileStart = () => ({
  type: types.UPDATE_COMPANY_PROFILE_START,
});

export const updateCompanyProfileSuccess = (payload) => ({
  type: types.UPDATE_COMPANY_PROFILE_SUCCESS,
  payload,
});

export const updateCompanyProfileFailed = (payload) => ({
  type: types.UPDATE_COMPANY_PROFILE_FAILURE,
  payload,
});

export const updateCompanyProfile = (newCompanyData) => (dispatch) => {
  dispatch(updateCompanyProfileStart());
  return axiosInstance
    .patch("/users/update_company", newCompanyData)
    .then(({ data }) => {
      dispatch(updateCompanyProfileSuccess(data));
      dispatch(displayNotification("Your changes have been saved successfully"));
    })
    .catch((err) => {
      dispatch(updateCompanyProfileFailed({ message: err.response.data.error }));
    });
};
