import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCase } from "../../redux/actions/getCase";
import { useHistory } from "react-router-dom";
import CreateCase from "../create-case/CreateCase";
import { addCase } from "../../redux/actions/createOrUpdateCase";

import "./case-details.css";

const CaseDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { id, action } = useParams();

  useEffect(() => {
    dispatch(getCase(id, history));
  }, [dispatch, id, history]);

  const currentCase = useSelector((state) => state.getCaseReducer.case);
  if (action === "rename") {
    dispatch(addCase(currentCase));
  }

  const editHandler = () => {
    dispatch(addCase(currentCase));
  };

  return (
    <Container className="details case-file-wrapper">
      <p className="title-2">Case Details</p>
      <div className="case-details-wrapper">
        <div>
          <div>
            <p className="title-3">Case Title</p>
            <p className="copy-2" id="case-details-case-title">{currentCase?.title}</p>
          </div>
          <div className="case-details-description">
            <p className="title-3">Description of case</p>
            <p className="copy-2" id="case-details-case-title">{currentCase?.description || "None given"}</p>
          </div>
        </div>
        <CreateCase
          trigger={
            <div className="editText" onClick={editHandler}>
              Edit
            </div>
          }
          initialStep={action === "rename" ? 3 : 0}
          open={action === "rename"}
        />
      </div>
    </Container>
  );
};

export default CaseDetails;
