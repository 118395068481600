import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ReportDetails from "../report-details/ReportDetails";
import RiskLevel from "../risk-level/RiskLevel";
import PendingResult from "../pending-result/PendingResult";
import ComingSoon from "../coming-soon/ComingSoon";

const Report = () => {
  const { action } = useParams();
  const report = useSelector((state) => state.getReportReducer?.report);

  if (report && [Object.keys(report).length, report.results?.length].includes(0)) {
    return <PendingResult />;
  } else if (action === "risk-level") {
    return <RiskLevel />;
  } else if (action === "actions") {
    return <ReportDetails />;
  }
  return <ComingSoon />;
};

export default Report;
