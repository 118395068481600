import React, { useState } from "react";
import { Modal, Image } from "semantic-ui-react";
import Astronaut from "../assets/astronaut-3.png";
import ResetPasswordForm from "./ResetPasswordForm";

import "./reset-password.css";

const ResetPassword = ({ trigger }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      className="modalWrapper reset-password-modal"
    >
      <Modal.Content>
        <div className="reset-password-wrapper">
          <Image src={Astronaut} />
          <ResetPasswordForm closeModal={() => setOpen(false)} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ResetPassword;
