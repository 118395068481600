import React, { useEffect, forwardRef } from "react";
import { Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InputText from "../input/InputText";
import MultiSelect from "../input/MultiSelect";
import Input from "../input/Input";
import useValidation from "../hooks/useValidation";
import { addProfile } from "../../redux/actions/createProfile";
import useOptions from "../hooks/useOptions";

const CompanyProfile = forwardRef(({ setValid }, ref) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.createProfileReducer.profile);

  const fields = [
    { name: "company_name", type: "text" },
    { name: "company_website", type: "url" },
    { name: "company_locations", type: "text" },
    { name: "number_of_employees", type: "text" },
    { name: "sector", type: "text" },
  ];
  const { error, setError } = useValidation({
    model: profile,
    ref,
    setValid,
    fields,
  });

  const updateProfile = (field, value) => {
    const newProfile = {
      ...profile,
      [field]: value,
    };
    dispatch(addProfile(newProfile));
    setError({ ...error, [field]: "" });
  };

  const options = useOptions();

  const { state } = useLocation();

  if (state) {
    useEffect(() => {
      dispatch(addProfile(state.profile));
    }, [dispatch, state]);
  }

  return (
    <>
      <div className="modal-heading-description">
        <p className="navig">Create an Account</p>
        <p className="title-1">Your Company Profile</p>
      </div>
      <Form size="big" className="form-background">
        <InputText
          error={error.company_name}
          title="Company name"
          value={profile.company_name}
          onChange={(event) => {
            updateProfile("company_name", event.target.value);
          }}
        />
        <InputText
          placeholder="www.companyhouse.com"
          error={error.company_website}
          title="Company website"
          value={profile.company_website}
          onChange={(event) => {
            updateProfile("company_website", event.target.value);
          }}
        />
        <MultiSelect
          placeholder="Select as many as applicable..."
          options={options.countriesOptions}
          error={error.company_locations}
          title="Company location(s)"
          value={profile.company_locations}
          onChange={(_, { value }) => {
            updateProfile("company_locations", value);
          }}
        />
        <Input
          placeholder="Please select one"
          options={options.sectorOptions}
          error={error.sector}
          title="Sector"
          value={profile.sector}
          onChange={(_, { value }) => {
            updateProfile("sector", value);
          }}
        />
        <Input
          placeholder="Please select one"
          options={options.retrievedFromOptions}
          error={error.number_of_employees}
          title="Number of employees"
          value={profile.number_of_employees}
          onChange={(_, { value }) => {
            updateProfile("number_of_employees", value);
          }}
        />
      </Form>
    </>
  );
});

export default CompanyProfile;
