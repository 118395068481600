import axiosInstance from "../../helpers/api";
import * as types from "./types";


export const getTeamStart = () => ({
  type: types.GET_TEAM_START,
});

export const getTeamSuccess = (payload) => ({
  type: types.GET_TEAM_SUCCESS,
  payload,
});

export const getTeamFailed = (payload) => ({
  type: types.GET_TEAM_FAILURE,
  payload,
});

export const getTeam = () => (dispatch) => {
  dispatch(getTeamStart());
  return axiosInstance
  .get(`/users/team`)
    .then(({ data }) => {
      dispatch(getTeamSuccess(data));
    })
    .catch((err) => {
      dispatch(getTeamFailed({ message: "Unable to fetch team member, something went wrong." }));
    });
};
