import React, { useState, useRef } from "react";
import { Button, Modal } from "semantic-ui-react";
import clsx from "clsx";
import PersonalProfile from "./PersonalProfile";
import CompanyProfile from "./CompanyProfile";
import GetStarted from "./GetStarted";
import useOptions from "../hooks/useOptions";
import useStepper from "../hooks/useStepper";
import "./create-account.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  removeProfile,
  createProfile,
} from "../../redux/actions/createProfile";
import Auth from "../../helpers/auth";

const CreateAccount = (props) => {
  const ref = useRef();
  const [valid, setValid] = useState(false);
  const options = useOptions();
  const [auth0Error, setAuth0Error] = useState({});

  const newUser = useSelector((state) => state.createProfileReducer.profile);

  const createSteps = React.useCallback(() => {
    return [
      <GetStarted />,
      <CompanyProfile setValid={setValid} ref={ref} />,
      <PersonalProfile setValid={setValid} ref={ref} />,
    ];
  }, []);

  const steps = createSteps(options);

  const {
    nextStep,
    previousStep,
    hasPrevious,
    hasNext,
    finish,
    content,
  } = useStepper({ steps });
  const [open, setOpen] = React.useState(props.open);
  const dispatch = useDispatch();

  let history = useHistory();

  const validate = React.useCallback(() => {
    const hasError = ref.current?.hasError();
    if (!hasError) {
      nextStep();
    }
  }, [nextStep]);

  const saveProfile = () => {
    const hasError = ref.current?.hasError();

    const handleAuth0Error = (error) => {
      if (error.code === "invalid_password") {
        setAuth0Error({
          password: "Password is too weak",
        });
      } else {
        setAuth0Error({
          email: error.description,
        });
      }
    };

    if (!hasError) {
      new Auth().register(newUser, (error) => {
        if (error) {
          handleAuth0Error(error);
        } else {
          dispatch(createProfile(history));
          finish();
          setOpen(false);
        }
      });
    }
  };

  const cancelHandler = () => {
    dispatch(removeProfile());
  };

  const enrichedContent = React.cloneElement(content, {
    nextStep,
    auth0Error,
  });

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
      size="tiny"
      className="create-account-wrapper"
    >
      <Modal.Content>{enrichedContent}</Modal.Content>

      {hasPrevious() && (
        <div className="modalActions">
          <div>
            {hasPrevious() && (
              <span className="back-button actionbtn" onClick={previousStep}>
                &lt; Back
              </span>
            )}
            <span
              className="cancel-btn actionbtn"
              onClick={() => {
                cancelHandler();
                setOpen(false);
                finish();
              }}
            >
              Cancel
            </span>
          </div>

          {hasNext() ? (
            <Button
              primary
              className={clsx("next-button", !valid && "disabled-btn")}
              onClick={validate}
            >
              Next
            </Button>
          ) : (
            <Button
              primary
              className={clsx("finish-button", !valid && "disabled-btn")}
              onClick={saveProfile}
            >
              Create Account
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CreateAccount;
