import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';

import "./index.css";
import 'semantic-ui-less/semantic.less'

import App from "./App";
import store from '../src/redux/store/index'
import 'typeface-open-sans';

ReactDOM.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById("root")
);
