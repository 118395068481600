import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import { getCase } from "../../redux/actions/getCase";

const Breadcrumbs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, action } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getCase(id, history));
    }
  }, [dispatch, id, history]);

  const SECTION = {
    profile: "Your Profile",
    company: "Company Profile",
    team: "Team",
    payment: "Payment & Plan",
    settings: "Settings"
  };

  const currentCase = useSelector((state) => state.getCaseReducer.case);
  const isCaseRoutes = useRouteMatch("/cases/:id");
  const isAccountRoutes = useRouteMatch("/account/:action");
  const isCaseReportRoutes = useRouteMatch("/cases/:id/report/:action");
  const titleize = {
    actions: "Report",
    "risk-level": "Risk Level",
    "advisor-review": "Advisor Review",
  };

  if (isCaseReportRoutes) {
    return (
      <Breadcrumb>
        <Breadcrumb.Section href="/cases">
          <p className="title-3">Cases</p>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href={`/cases/${id}/edit`}>
          <p className="title-3">Case File</p>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          <p className="copy-3">{titleize[action]}</p>
        </Breadcrumb.Section>
      </Breadcrumb>
    );
  }

  if (isCaseRoutes && currentCase?.status === "draft") {
    return (
      <Breadcrumb>
        <Breadcrumb.Section href="/cases">
          <p className="title-3">Cases</p>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          <p className="copy-3">Open a New Case</p>
        </Breadcrumb.Section>
      </Breadcrumb>
    );
  }

  if (isCaseRoutes) {
    return (
      <Breadcrumb>
        <Breadcrumb.Section href="/cases">
          <p className="title-3">Cases</p>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          <p className="copy-3">Active Case</p>
        </Breadcrumb.Section>
      </Breadcrumb>
    );
  }
  if (isAccountRoutes) {
    return (
      <Breadcrumb>
        <Breadcrumb.Section href="/account/profile">
          <p className="title-3">Account</p>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          <p className="copy-3">{SECTION[action]}</p>
        </Breadcrumb.Section>
      </Breadcrumb>
    );
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Section>
        <p className="copy-3">Cases</p>
      </Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
