import axiosInstance from "../../helpers/api";
import * as types from "./types";

export const createTeamStart = () => ({
  type: types.CREATE_TEAM_START,
});

export const createTeamSuccess = (payload) => ({
  type: types.CREATE_TEAM_SUCCESS,
  payload,
});

export const createTeamFailed = (payload) => ({
  type: types.CREATE_TEAM_FAILED,
  payload,
});

export const clearCreateTeamErrors = () => ({type: types.CLEAR_CREATE_TEAM_ERRORS});

export const createTeam = (newTeamMember) => (dispatch) => {
  const body = {
    first_name: newTeamMember.first_name,
    last_name: newTeamMember.last_name,
    job_title: newTeamMember.job_title,
    email: newTeamMember.email,
    role:newTeamMember.role.toLowerCase()
  };

  dispatch(createTeamStart());
  return axiosInstance
    .post("/users/add_team", body)
    .then(({ data }) => {
      dispatch(createTeamSuccess(data));
    })
    .catch((err) => {
      dispatch(createTeamFailed({ message: "Unable to add team member, something went wrong." }))
    });
};
