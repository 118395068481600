import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import Input from "../input/Input";
import MultiSelect from "../input/MultiSelect";
import { addCase, removeCase } from "../../redux/actions/createOrUpdateCase";
import useValidation from "../hooks/useValidation";
import useOptions from "../hooks/useOptions";
import {
  dataSubjectTooltip,
  dataTypeTooltip,
  originTooltip,
  retrievedFromTooltip,
} from "../constant/tooltips";

const DataRequest = forwardRef(({ setValid }, ref) => {
  const dispatch = useDispatch();
  const singleCase = useSelector((state) => state.createCaseReducer.case);
  const fields = [
    { name: "subject", type: "text" },
    { name: "types_collected", type: "text" },
    { name: "origins", type: "text" },
    { name: "retrieved_from", type: "text" },
  ];
  const { error, setError } = useValidation({
    model: singleCase,
    ref,
    setValid,
    fields,
  });

  const updateCase = (field, value) => {
    const newCase = {
      ...singleCase,
      [field]: value,
    };
    dispatch(addCase(newCase));
    setError({ ...error, [field]: "" });
  };

  const updateSubject = (subject, sub_subject) => {
    const newCase = {
      subject,
      sub_subject,
    };
    dispatch(removeCase());
    dispatch(addCase(newCase));
    setError({ ...error, subject: "" });
  };

  const options = useOptions();

  return (
    <>
      <div className="modal-heading-description">
        <p className="navig">Data Request</p>
        <p className="modalTitle">What data do you want to access?</p>
      </div>
      <Form size="big" className="form-background">
        <Input
          placeholder="Please select one"
          options={options.dataSubjectOptions}
          error={error.subject}
          title="Data subject"
          instruction="You can add additional data subjects later"
          tooltip={dataSubjectTooltip}
          value={JSON.stringify({
            subject: singleCase.subject,
            sub_subject: singleCase.sub_subject,
          })}
          onChange={(_, { value }) => {
            const { subject, sub_subject } = JSON.parse(value);
            updateSubject(subject, sub_subject);
            options.setSubjectData(value);
          }}
        />
        <MultiSelect
          placeholder="Select as many as applicable"
          options={options.typesCollectedOptions}
          error={error.types_collected}
          title="Type of data collected"
          tooltip={dataTypeTooltip}
          value={singleCase.types_collected}
          onChange={(_, { value }) => {
            updateCase("types_collected", value);
          }}
        />
        <MultiSelect
          placeholder="Select as many as applicable"
          options={options.countriesOptions}
          error={error.origins}
          title="Origin of data"
          tooltip={originTooltip}
          value={singleCase.origins}
          onChange={(_, { value }) => {
            updateCase("origins", value);
          }}
        />
        <Input
          placeholder="Please select one"
          options={options.retrievedFromOptions}
          error={error.retrieved_from}
          title="Number of people data is retrieved from"
          tooltip={retrievedFromTooltip}
          value={singleCase.retrieved_from}
          onChange={(_, { value }) => {
            updateCase("retrieved_from", value);
          }}
        />
      </Form>
    </>
  );
});

export default DataRequest;
