import React from "react";
import { Button, Image, Segment } from "semantic-ui-react";
import Astronaut1 from "../assets/astronaut-1.png";
import CreateAccount from "../account/CreateAccount";
import './login.css';

const LoginRight = () => (
  <Segment
    basic
    placeholder
    textAlign="center"
    className="login-right-container"
    vertical
  >
    <Image centered src={Astronaut1} />
    <span className="title-1">Don't have a Privitar account?</span>
    <p className="copy-1 tagline">
      Privitar combines artficial intelligence with a team of expert legal
      advisors to make navigating data privacy laws simple.
    </p>
    <CreateAccount trigger={<Button primary content="Create an Account" className="transparent"/>} />
    <span className="what-is-kormoon">
      <a
        href="https://www.privitar.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="title-4"
      >
        What is Privitar?
      </a>
    </span>
  </Segment>
);

export default LoginRight;
