import React from "react";
import { Modal, Popup } from "semantic-ui-react";
import Action from './Action';
import { ReactComponent as IconHelp } from "../assets/icon-help.svg";

import "./list-actions.css";

const ListActions = ({ trigger, jurisdiction, answer, notes, rules }) => {
  const [open, setOpen] = React.useState(false);

  const showRule = ([description, rule]) => (
    rule.results.filter((result) => result.action_item).length > 0
  );

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      className="list-actions-modal"
    >
      <Modal.Content className="list-actions-modal-content">
        <div className="list-actions-wrapper">
          <div className="list-title">
            <span className="title-1">{jurisdiction}</span>
            <p>
              {answer}
            </p>
          </div>
          <div className="list-content">
            <div className="report-notes">
              <div className="title-2">Report Notes</div>
              <p className="copy-2">{notes}</p>
            </div>
            <div className="list-actions">
              {
                Object.entries(rules).filter(showRule).map(([description, rule], index) => {
                  return (
                    <div className="list-action" key={index}>
                      <div className="list-action-label">
                        <div className="title-2">{description}
                          {rule.rationale && (
                            <Popup
                              className="tooltip"
                              trigger={<IconHelp className="tooltip-icon" size="large" circular="true" />}
                              content={rule.rationale}
                              position="top center"
                            />
                          )}
                        </div>
                      </div>
                      {
                        rule.results.filter((result) => result.action_item).map((result, index) => (
                          <Action
                            key={index}
                            resultId={result.id}
                            name={result.action_item.name}
                            description={result.action_item.description}
                            actionable={result.action_item.actionable}
                            status={result.status}
                          />
                        ))
                      }
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

ListActions.defaultProps = {
  rules: [],
};

export default ListActions;
