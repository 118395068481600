import {
  REPLICATE_CASE_START,
  REPLICATE_CASE_SUCCESS,
  REPLICATE_CASE_FAILURE,
} from "../actions/types";

const initialState = {
  isLoading: false,
  isCaseReplicated: false,
  errors: null,
};

const replicateCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPLICATE_CASE_START:
      return {
        ...state,
        isLoading: true,
      };
    case REPLICATE_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCaseReplicated: true,
      };
    case REPLICATE_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default replicateCaseReducer;

