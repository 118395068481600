import React from "react";
import { Segment, Image } from "semantic-ui-react";
import Astronaut1Image from "../assets/astronaut-1.png";
import "./error.css";

const Error = () => {
  return (
    <Segment
      basic
      placeholder
      textAlign="center"
      className="error-container"
      vertical
    >
      <Image centered src={Astronaut1Image} />
      <p className="title-1">Houston, we have a problem...</p>
      <p className="copy-1">
        Well, it’s not that bad! But there was a problem loading that screen.
        Perhaps the content you’re looking for isn’t here anymore...
      </p>
    </Segment>
  );
};

export default Error;
