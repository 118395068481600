import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  team: [],
  errors: null,
};

const getTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TEAM_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_TEAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        team: action.payload,
      };
    case types.GET_TEAM_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default getTeamReducer;
