export const GET_CASE_START = "GET_CASE_START";
export const GET_CASE_SUCCESS = "GET_CASE_SUCCESS";
export const GET_CASE_FAILURE = "GET_CASE_FAILURE";
export const ADD_CASE = "ADD_CASE";
export const REMOVE_CASE = "REMOVE_CASE";

export const CREATE_OR_UPDATE_CASE_START = "CREATE_OR_UPDATE_CASE_START";
export const CREATE_OR_UPDATE_CASE_FAILURE = "CREATE_OR_UPDATE_CASE_FAILURE";

export const CREATE_CASE_SUCCESS = "CREATE_CASE_SUCCESS";
export const UPDATE_CASE_SUCCESS = "UPDATE_CASE_SUCCESS";

export const UPDATE_RESULT_START = "UPDATE_RESULT_START";
export const UPDATE_RESULT_SUCCESS = "UPDATE_RESULT_SUCCESS";
export const UPDATE_RESULT_FAILURE = "UPDATE_RESULT_FAILURE";

export const LOAD_OPTIONS_START = "LOAD_OPTIONS_START";
export const LOAD_OPTIONS_SUCCESS = "LOAD_OPTIONS_SUCCESS";
export const LOAD_OPTIONS_FAILURE = "LOAD_OPTIONS_FAILURE";

export const ACTIVATE_CASE_START = "ACTIVATE_CASE_START";
export const ACTIVATE_CASE_SUCCESS = "ACTIVATE_CASE_SUCCESS";
export const ACTIVATE_CASE_FAILURE = "ACTIVATE_CASE_FAILURE";

export const GET_SINGLE_CASE_START = "GET_SINGLE_CASE_START";
export const GET_SINGLE_CASE_SUCCESS = "GET_SINGLE_CASE_SUCCESS";
export const GET_SINGLE_CASE_FAILURE = "GET_SINGLE_CASE_FAILURE";

export const GET_REPORT_START = "GET_REPORT_START";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const CREATE_PROFILE_START = "CREATE_PROFILE_START";
export const CREATE_PROFILE_FAILURE = "CREATE_PROFILE_FAILURE";

export const ADD_PROFILE = "ADD_PROFILE";
export const REMOVE_PROFILE = "REMOVE_PROFILE";

export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";

export const GET_CURRENT_USER_START = "GET_CURRENT_USER_START";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";

export const DELETE_CASE_START = "DELETE_CASE_START";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_FAILURE = "DELETE_CASE_FAILURE";

export const REPLICATE_CASE_START = "REPLICATE_CASE_START";
export const REPLICATE_CASE_SUCCESS = "REPLICATE_CASE_SUCCESS";
export const REPLICATE_CASE_FAILURE = "REPLICATE_CASE_FAILURE";

export const UPDATE_COMPANY_PROFILE_START = "UPDATE_COMPANY_PROFILE_START";
export const UPDATE_COMPANY_PROFILE_SUCCESS = "UPDATE_COMPANY_PROFILE_SUCCESS";
export const UPDATE_COMPANY_PROFILE_FAILURE = "UPDATE_COMPANY_PROFILE_FAILURE";

export const ADD_COMPANY = "ADD_COMPANY";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const DISPLAY_SUCCESS_NOTIFICATION = "DISPLAY_SUCCESS_NOTIFICATION";
export const DISPLAY_CLEAR_NOTIFICATION = "DISPLAY_CLEAR_NOTIFICATION";

export const GET_TEAM_START = "GET_TEAM_START"
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS"
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE"

export const CREATE_TEAM_START = "CREATE_TEAM_START";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAILED = "CREATE_TEAM_FAILED";
export const CLEAR_CREATE_TEAM_ERRORS = "CLEAR_CREATE_TEAM_ERRORS";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";