import axiosInstance from "../../helpers/api";
import { getItem, removeItem, setItem } from "../../helpers/localStorage";
import * as types from "./types";
import store from "../store";

export const createOrUpdateCaseStart = () => ({
  type: types.CREATE_OR_UPDATE_CASE_START,
});

export const createCaseSuccess = (payload) => ({
  type: types.CREATE_CASE_SUCCESS,
  payload,
});

export const updateCaseSuccess = (payload) => ({
  type: types.UPDATE_CASE_SUCCESS,
  payload,
});

export const createOrUpdateCaseFailed = (payload) => ({
  type: types.CREATE_OR_UPDATE_CASE_FAILURE,
  payload,
});

export const addCase = (singleCase) => (dispatch) => {
  const localstorageCase = getItem("singleCase") || {};

  setItem("singleCase", { ...localstorageCase, ...singleCase });
  dispatch({
    type: types.ADD_CASE,
    payload: singleCase,
  });
};

export const removeCase = () => (dispatch) => {
  removeItem("singleCase");

  dispatch({
    type: types.REMOVE_CASE,
  });
};

export const createOrUpdateCase = (history) => (dispatch) => {
  const {
    createCaseReducer: { case: existingCase },
  } = store.getState();

  dispatch(createOrUpdateCaseStart());

  if (existingCase.id) {
    return axiosInstance
      .patch(`/cases/${existingCase.id}`, existingCase)
      .then(({ data }) => {
        dispatch(updateCaseSuccess(data));
        dispatch(removeCase());
        history.push(`/cases/${data.id}/edit`);
      })
      .catch((err) => {
        dispatch(createOrUpdateCaseFailed({ message: err.response.data.error }));
      });
  }

  return axiosInstance
    .post("/cases", existingCase)
    .then(({ data }) => {
      dispatch(createCaseSuccess(data));
      dispatch(removeCase());
      history.push(`/cases/${data.id}/edit`);
    })
    .catch((err) => {
      dispatch(createOrUpdateCaseFailed({ message: err.response.data.error }));
    });
};
