import React from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import { ReactComponent as IconHelp } from "../assets/icon-help.svg";
import "./input.css";

const MultiSelect = (props) => {
  const showDropdownInput = (event) => {
    const target = event.target;
    const input = target
      .closest('div[role="combobox"]')
      .querySelector('input');
    input.style.display = 'inline-block';
  }

  const hideDropdownInput = (event) => {
    event.target.style.display = 'none';
  }

  return (
    <div className="input-wrapper">
      <div className="label-wrapper">
        <div className="label-left">
          <span className="title-3">{props.title}</span>
          {props.tooltip && (
            <Popup
              className="tooltip"
              trigger={<IconHelp size="large" circular="true" />}
              content={props.tooltip}
              position="top center"
            />
          )}
        </div>
        <span className="copy-4 label-right">{props.instruction}</span>
      </div>

      <Dropdown
        className="multi-select"
        placeholder={props.placeholder}
        fluid
        multiple
        search
        selection
        lazyLoad
        onChange={props.onChange}
        value={props.value}
        options={props.options}
        onClick={showDropdownInput}
        onBlur={hideDropdownInput}
        style={(props.error !== '' && props.error !== undefined) ? { borderColor: "#CF2C67" } : {}}
      />

      <span className="error-message">{props.error}</span>
    </div>
  );
};

MultiSelect.defaultProps = {
  options: [],
  value: [],
};

export default MultiSelect;
